<template>
  <flex-layout styleClasses="p-24">
    <page-header heading="Home" action="Add" />
    <div class="pt-24 px-24">
      <div class="row">
        <div v-for="(item, index) in countInfo" :key="index" class="col-md-3">
          <div class="card-dashboard" :class="cardColor()">
            <p class="fw-semibold text-white"> {{ index }} </p>
            <h4 class="mt-4 text-white">{{ item }}</h4>
          </div>
          <br>
        </div>
      </div>
    </div>


    <!-- <div class="row mt-24">
        <div class="col-md-6">
          <CompanyTypePie />
        </div>

        <div class="col-md-6">
          <UsersPie />
        </div>
      </div> -->
  </flex-layout>
</template>

<script>
import FlexLayout from "../../components/Layout/FlexLayout.vue";
import PageHeader from "../../components/Utility/PageHeader.vue";
// import CompanyTypePie from "@/components/Chart/CompanyTypePie.vue";
// import UsersPie from "@/components/Chart/UsersPie.vue";
import axios from "axios";

export default {
  name: "Dashboard",
  components: {
    PageHeader,
    FlexLayout,
    // CompanyTypePie,
    // UsersPie,
  },
  data() {
    return {
      countInfo: {}
    }
  },
  mounted() {
    this.fetchCounts();
  },
  methods: {
    async fetchCounts() {
      let uri = process.env.VUE_APP_API_URL + "count";
      try {
        const { data } = await axios.get(uri);
        this.countInfo = data;
        console.log(this.countInfo);
      } catch (error) {
        console.log(error);
      }
    },

    cardColor() {
      let num = Math.floor(Math.random() * 4) + 1;
      if (num % 2 == 0) {
        return "card1";
      } else {
        return "card2";
      }
    }
  },
};
</script>


<style scoped>
.card1 {
  background: rgb(53, 56, 107);
  background: linear-gradient(90deg, rgba(53, 56, 107, 1) 0%, rgba(164, 46, 42, 1) 33%);
}

.card2 {
  background: rgb(53, 56, 107);
  background: linear-gradient(315deg, rgba(53, 56, 107, 1) 17%, rgba(164, 46, 42, 1) 50%);
}
</style>