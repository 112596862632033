<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Office</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
                    <div class="col-md-8 mb-16">
                        <label for="">Title</label>
                        <input type="text" class="form-control" v-model="item.title" />
                        <ErrorSpan :error="errors.title" />
                    </div>
                   
                    <div class="col-md-4 mb-16">
                        <label for="">Type</label>
                        <select name="type" class="form-select" :class="{'border-red':errors.type}"
                            v-model="item.type">
                            <option value="" selected>Select Type</option>
                            <option v-for="(type,key) in types" :key="key" :value="type.id">{{type.type}}</option>
                        </select>
                        <ErrorSpan :error="errors.type" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Email</label>
                        <input type="email" class="form-control" v-model="item.email" />
                        <ErrorSpan :error="errors.email" />
                    </div> 

                    <div class="col-md-12 mb-16">
                        <label for="">Address</label>
                        <input type="text" class="form-control" v-model="item.address" />
                        <ErrorSpan :error="errors.address" />
                    </div>
                    <div class="col-md-6 mb-16">
                        <label for="">Contact Number</label>
                        <input type="text" class="form-control" v-model="item.number" />
                        <ErrorSpan :error="errors.number" />
                    </div>
                    <div class="col-md-6 mb-16">
                        <label for="">Fax Number</label>
                        <input type="text" class="form-control" v-model="item.fax_number" />
                        <ErrorSpan :error="errors.fax_number" />
                    </div>
                    <div class="col-md-12 mb-16">
                        <label for="">Contact Person</label>
                        <input type="text" class="form-control" v-model="item.contact_person" />
                        <ErrorSpan :error="errors.contact_person" />
                    </div>
                    <div class="col-md-6 mb-16">
                        <label for="">Display Order</label>
                        <input type="number" min="1"
                            @input="item.display_order=item.display_order<1?'':item.display_order" class="form-control"
                            :class="{'border-red':errors.display_order}" v-model="item.display_order" />
                        <span v-if="errors.display_order"
                            class="text-danger font-size-13">{{errors.display_order}}</span>
                    </div>
                    <div class="col-md-6 mb-16">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                v-model="item.status" />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
                        </div>
                    </div>
                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <button v-if="!isLoading" class="btn btn-green" type="submit">
                            <i class="ic-checkmark mr-8"></i>Save
                        </button>
                        <button v-else class="btn btn-green" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";

export default {
    name: "OfficeModal",
    mixins: [CreateMixin],
    components: { ErrorSpan },
    data() {
        return {
            url: 'office',
            mode: "",
            types: [
                { id: '1', type: 'Head Office' },
                { id: '2', type: 'Branch Office' }
            ],
            fetch_module:'FETCH_OFFICEs'
        };
    },
    computed: {

    },
    mounted() {
        this.$root.$on('CREATE', () => {
            this.isEdit = false;
            this.item = {
            };
            this.errors = {};
            this.mode = "Add";
        });

        this.$root.$on('EDIT', (data) => {
            this.errors = {};
            this.isEdit = true;
            this.item = { ...data };
            this.mode = "Edit";
        });

    },
    methods: {
    },
};
</script>
  
<style scoped>

</style>
  