<template>
  <div
    class="offcanvas offcanvas-end offcanvas-01"
    id="offcanvasCreate"
    tabindex="-1"
    aria-labelledby="offcanvasCreateLabel"
  >
    <div class="offcanvas-header">
      <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Page</h5>
      <button
        class="btn btn-danger btn-sm"
        type="button"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        ref="btnClose"
      >
        <i class="ic-close"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <form
          class="row align-end"
          v-on:submit.prevent="submitForm"
          enctype="multipart/form-data"
        >
          <div class="col-md-6 mb-16">
            <label for="">Name</label>
            <input
              type="text"
              class="form-control"
              :class="{ 'border-red': errors.name }"
              v-model="item.name"
            />
            <span v-if="errors.name" class="text-danger mt-2 font-size-13">{{
              errors.name
            }}</span>
          </div>
          <div class="col-md-6 mb-16">
            <label for="">Title</label>
            <input
              type="text"
              class="form-control"
              :class="{ 'border-red': errors.title }"
              v-model="item.title"
            />
            <span v-if="errors.title" class="text-danger mt-2 font-size-13">{{
              errors.title
            }}</span>
          </div>
          <div class="col-md-6 mb-16">
            <label for="">Slug</label>
            <input
              type="text"
              class="form-control"
              :class="{ 'border-red': errors.slug }"
              v-model="item.slug"
            />
            <span v-if="errors.slug" class="text-danger mt-2 font-size-13">{{
              errors.slug
            }}</span>
          </div>

          <div class="col-md-6 mb-16">
            <label for="">Menu</label>
            <select
              name="company_id"
              class="form-select"
              :class="{ 'border-red': errors.menu_id }"
              v-model="item.menu_id"
            >
              <option value="" disabled selected>Select Menu</option>
              <option v-for="(cat, key) in menus" :key="key" :value="cat.id">
                {{ cat.name }}
              </option>
            </select>
            <ErrorSpan :error="errors.menu_id" />
          </div>

          <div class="col-md-4 mb-16">
            <label for="">Image</label>
            <input
              type="file"
              class="form-control"
              :class="{ 'border-red': errors.image }"
              ref="file"
              v-on:change="handleFileUpload()"
            />
            <span v-if="errors.image" class="text-danger mt-2 font-size-13">{{
              errors.image
            }}</span>
          </div>
          <div class="col-md-2" v-if="upload.status == false">
            <a v-if="item.image" :href="storage + item.image" target="_blank">
              <img :src="storage + item.image" alt="" height="50" width="50" />
            </a>
          </div>
          <div class="col-md-2" v-else>
            <img :src="upload.imgPreview" alt="" height="50" width="50" />
          </div>
          <div class="col-md-12 mb-16">
            <label for="">Content</label>
            <ckeditor v-model="item.content"></ckeditor>
            <!-- <textarea
                    class="form-control"
                    v-model="item.content"
                ></textarea> -->
            <span v-if="errors.content" class="text-danger mt-2 font-size-13">{{
              errors.content
            }}</span>
          </div>
          <div class="col-md-6 mb-16">
            <label for="">Display Order</label>
            <input
              type="number"
              min="1"
              @input="item.order_by = item.order_by < 1 ? '' : item.order_by"
              class="form-control"
              :class="{ 'border-red': errors.order_by }"
              v-model="item.order_by"
            />
            <span v-if="errors.order_by" class="text-danger font-size-13">{{
              errors.order_by
            }}</span>
          </div>
          <div class="col-md-6 mb-16">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                v-model="item.status"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Status</label
              >
            </div>
          </div>
          <div class="col-md-12">
            <button
              type="button"
              @click="closeForm()"
              aria-label="Close"
              class="btn btn-red mr-16"
            >
              <i class="ic-close mr-8"></i>Cancel
            </button>
            <button v-if="!isLoading" class="btn btn-green" type="submit">
              <i class="ic-checkmark mr-8"></i>Save
            </button>
            <button v-else class="btn btn-green" type="button" disabled>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CreateMixin from "../../mixins/create.vue";
import UploadPreviewMixin from "../../mixins/uploadpreview.vue";
import CKEditor from "ckeditor4-vue";
import ErrorSpan from "@/components/errorspan.vue";

export default {
  name: "Create",
  components: {
    ckeditor: CKEditor.component,ErrorSpan
  },
  mixins: [CreateMixin, UploadPreviewMixin],
  data() {
    return {
      url: "page",
      menus: {},
      fetch_module: "FETCH_PAGEs",
    };
  },
  created() {
    this.$root.$on("CREATE", () => {
      this.isEdit = false;
      this.item = {
        status: false,
        slug: "",
      };
      this.errors = {};
      this.mode = "Add";
      this.clearImagePreview();
      this.clearselectedImage();
    });

    this.$root.$on("EDIT", (data) => {
      this.mode = "Edit";
      this.isEdit = true;
      this.item = { ...data };
      this.clearImagePreview();
      this.clearselectedImage();
    });

    this.getMenus();
  },
  methods: {
    clearselectedImage() {
      if (this.$refs.file) {
        this.$refs.file.value = null;
      }
    },
    handleFileUpload() {
      let file = this.$refs.file.files[0];
      this.upload.status = true;
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          this.upload.imgPreview = reader.result;
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
        this.item.image = file;
      }
      console.log(this.$refs.file);
    },
    closeForm() {
      this.$refs.btnClose.click();
    },
    async getMenus() {
      let url = process.env.VUE_APP_API_URL + "page/get-menus";
      let response = await this.axios.get(url);
      this.menus = response.data;
      console.log(this.menus);
    },
  },
};
</script>
