<template>
    <flex-layout>
        <page-header heading="Open Career" />
        <OpenCareerTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import OpenCareerTable from "@/components/OpenCareer/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
export default {
    name: "OpenCareer",
    components: {
        PageHeader,
        FlexLayout,
        OpenCareerTable,

    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Name",
                },
                {
                    heading: "Email",
                },
                {
                    heading: "Contact Number",
                },
                {
                    heading: "CV",
                },
                {
                    heading: "Cover Letter",
                },
                {
                    heading: "Submitted On",
                }
            ],
        };
    },

};
</script>
  