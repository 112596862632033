<template>
  <flex-layout>
    <CreateMenu :parents="parents" />
    <page-header
      heading="Product/Services Category"
      action="Add"
      toggle="offcanvas"
      target="#offcanvasCreate"
      @click="setCreateForm"
      :guard="this.$route.meta.guard"
    />

    <Table :tableHeading="tableHeading" />
  </flex-layout>
</template>

<script>
import FlexLayout from "../../components/Layout/FlexLayout.vue";
import CreateMenu from "./create.vue";
import Table from "./table.vue";
import PageHeader from "../../components/Utility/PageHeader.vue";
export default {
  name: "Menus",
  components: {
    PageHeader,
    FlexLayout,
    CreateMenu,
    Table,
  },
  data() {
    return {
      link_types: {},
      menu_types: {},
      parents: {},

      tableHeading: [
        {
          heading: "Category Name",
        },
        {
            heading: "Slug",
          },
        {
          heading: "Category Description",
        }
      ],
    };
  },
  async created() {
    this.fetchParents();
  },
  methods: {
    setCreateForm() {
      this.$root.$emit("CREATE");
    },

    async fetchParents() {
      let url = process.env.VUE_APP_API_URL + "product-service-category/getParents";
      let { data } = await this.axios.get(url);
      this.parents = data;
    },
  },
};
</script>
