
<template>
    <flex-layout>
        <page-header heading="Career Applications" toggle="offcanvas" target="#offcanvasCreate" />
        <CareerApplicationTable :tableHeading="tableHeading"/>
    </flex-layout>

</template>

<script>
import PageHeader from "@/components/Utility/PageHeader.vue";
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import CareerApplicationTable from "@/components/CareerApplications/table.vue";
export default {
    name: 'CareerApplications',
    components: { PageHeader, FlexLayout,CareerApplicationTable },

    data() {
        return {
            tableHeading: [
                {
                    heading: "Name",
                },
                {
                    heading: "Email",
                },
                {
                    heading: "Contact Number",
                },
                {
                    heading: "CV",
                },
                {
                    heading: "Cover Letter",
                },
                {
                    heading: "Submitted On",
                }
            ],

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

