<template>
    <flex-layout>
        <OfficeModal/>
        <page-header heading="Office" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />

        <OfficeTable  :tableHeading="tableHeading"/>
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import OfficeTable from "@/components/Office/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import OfficeModal from "@/components/Office/modal.vue";
export default {
    name: "Office",
    components: {
        // AbsoluteLayout,
        PageHeader,
        FlexLayout,
        OfficeTable,
        OfficeModal
    },
    data() {
        return {
            link_types: {},
            menu_types: {},
            parents: {},

            tableHeading: [
                {
                    heading: "Title",
                }, {
                    heading: "Type",
                },
                {
                    heading: "Address",
                },
                {
                    heading: "Number",
                },
                {
                    heading: "Fax Number",
                },
                {
                    heading: "Contact Person",
                },
                {
                    heading: "Status",
                },
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  