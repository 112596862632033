<template>
  <div class="sidebar-01" :class="{ toggled: isToggled }">
    <div class="position-relative h-100">
      <absolute-layout :scrollable="true">
        <ul class="sidebar-01-list">
          <template v-for="data in menuList">
            <template v-if="data.hasSub && menuGuard(data, data.hasSub)">
              <li :key="data.index" :class="{ hasSub: data.hasSub }">
                <a type="button" data-bs-toggle="collapse" :data-bs-target="'#multiCollapseExample2_' + data.name"
                  aria-expanded="false" :aria-controls="'multiCollapseExample2_' + data.name">
                  {{ data.title }}
                </a>
                <div class="collapse multi-collapse" :id="'multiCollapseExample2_' + data.name">
                  <div class="card">
                    <template v-for="item in data.subMenu">
                      <router-link :to="item.link" v-if="menuGuard(item)" :key="item.index">
                        {{ item.innerTitle }}
                      </router-link>
                    </template>
                  </div>
                </div>
              </li>
            </template>
            <template v-else-if="menuGuard(data)">
              <li :key="data.index" :class="{ hasSub: data.hasSub }">
                <router-link :to="data.link"> {{ data.title }} </router-link>
              </li>
            </template>
          </template>
        </ul>
      </absolute-layout>
    </div>
  </div>
</template>

<script>
import AbsoluteLayout from "../Layout/AbsoluteLayout.vue";
export default {
  name: "AppSidebar",
  components: { AbsoluteLayout },
  props: { isToggled: Boolean },
  data() {
    return {
      menuList: [
        {
          title: "Home",
          link: "/",
          hasSub: false,
          guard: "home",
        },
        {
          title: "Carousel/ PopUp",
          name: "carousel_popup",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Carousel",
              link: "/carousel",
              guard: "carousel",
            },
            {
              innerTitle: "PopUp",
              link: "/manage-popups",
              guard: "popup",
            },
          ],
        },
        {
          title: "About Us",
          name: "about_us",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Corporate Profile",
              link: "/corporate-profile",
              guard: "corporateprofile",
            },
            {
              innerTitle: "Capital Structure",
              link: "/capital-structure",
              guard: "capitalstructure",
            },
            {
              innerTitle: "Member",
              link: "/member",
              guard: "member",
            },
            {
              innerTitle: "Aml Compliance",
              link: "/aml-compliance",
              guard: "amlcompliance",
            },
            {
              innerTitle: "Department Type",
              link: "/department-type",
              guard: "departmenttype",
            },
            {
              innerTitle: "Department",
              link: "/department",
              guard: "department",
            }
          ],
        },
        {
          title: "Media",
          name: "media",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Album",
              link: "/album",
              guard: "album",
            },
            {
              innerTitle: "Images",
              link: "/gallery",
              guard: "gallery",
            },
          ],
        },
        {
          title: "Investor Relation",
          name: "investor_relation",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Category",
              link: "/investor-relation-category",
              guard: "investorrelationcategory",
            },
            {
              innerTitle: "Investor Relation",
              link: "/investor-relation",
              guard: "investorrelation",
            },
          ],
        },
        {
          title: "Career",
          name: "career",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Openings",
              link: "/openings",
              guard: "careeropening",
            },
            {
              innerTitle: "Applications",
              link: "/applications",
              guard: "careerapplication",
            },
            {
              innerTitle: "Open Career",
              link: "/opencareer",
              guard: "opencareers",
            },
          ],
        },
        {
          title: "Login Links",
          link: "/login-link",
          hasSub: false,
          guard: "loginlink",
        },
        {
          title: "Role Management",
          name: "role_management",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Role Setup",
              link: "/role-setup",
              guard: "user",
            },
            {
              innerTitle: "User Role",
              link: "/user-role",
              guard: "user",
            },
          ],
        },
        {
          title: "Office Management",
          name: "office_management",
          hasSub: false,
          link: "/office",
          guard: "office",
        },

        {
          title: "Menus",
          link: "/menus",
          hasSub: false,
          guard: "menu",
        },
        {
          title: "Products/Services",
          name: "product_service",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Category",
              link: "/product-services-category",
              guard: "productservicecategory",
            },
            {
              innerTitle: "Product/Services",
              link: "/product-services",
              guard: "productservice",
            },
            {
              innerTitle: "Product ShowCase",
              link: "/product-showcase",
              guard: "productshowcase",
            },
            {
              innerTitle: "Portfolio Interested List",
              link: "/interested-list",
              guard: "productinterestedlist",
            },
            {
              innerTitle: "Advisory Interested List",
              link: "/advisory-interested-list",
              guard: "productinterestedlist",
            },
            {
              innerTitle: "Registrar",
              link: "/registrar",
              guard: "registrar",
            },



            // {
            //   innerTitle: "Page",
            //   link: "/pages",
            //   guard: "page",
            // },
          ],
        },
        {
          title: "Checkpoints",
          name: "results",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Company",
              link: "/company",
              guard: "company",
            },
            {
              innerTitle: "Tax Liability Check",
              link: "/tax-liability-check",
              guard: "taxliabilitycheck",
            },
            {
              innerTitle: "Right Share Eligibility Check",
              link: "/right-share-eligibility-check",
              guard: "rightshareeligibilitycheck",
            },
            {
              innerTitle: "Rejected DRN List Check",
              link: "/rejected-drn-list-check",
              guard: "rejecteddrnlistcheck",
            },
            {
              innerTitle: "Right Share Allotment Check",
              link: "/right-share-allotment",
              guard: "rightshareallotment",
            },
            {
              innerTitle: "Cash Dividend Check",
              link: "/cash-dividend-check",
              guard: "cashdividendcheck",
            },
            {
              innerTitle: "Invalid Bank Details Check",
              link: "/invalid-bank-details",
              guard: "invalidbankdetails",
            },
            {
              innerTitle: "Share Allotment Check",
              link: "/share-allotment-check",
              guard: "shareallotmentcheck",
            },
            {
              innerTitle: "Refund Detail Check",
              link: "/refund-detail-check",
              guard: "refunddetailcheck",
            },
            {
              innerTitle: "Debenture Interest Check",
              link: "/debenture-interest-check",
              guard: "debentureinterestcheck",
            },
            {
              innerTitle: "Uncollected Cash Dividend Check",
              link: "/uncollected-cash-dividend-check",
              guard: "uncollectedcashdividend",
            },
            {
              innerTitle: "Final Call Check",
              link: "/final-call-check",
              guard: "finalcallcheck",
            },
            {
              innerTitle: "WACC Calculation Check",
              link: "/wacc-check",
              guard: "wacccheck",
            },
          ],
        },
        {
          title: "Right IPO Validity",
          link: "/right-ipo-validity",
          hasSub: false,
          guard: "rightipovalidity",
        },
        {
          title: "Downloads",
          name: "download",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Category",
              link: "/download-category",
              guard: "downloadcategory",
            },
            {
              innerTitle: "Download",
              link: "/download",
              guard: "download",
            },
          ],
        },
        {
          title: "Balance Fund",
          link: "/balancefund",
          hasSub: false,
          guard: "balancefund",
        },
        {
          title: "Mutual Fund",
          name: "mutual_fund",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Mutual Fund Scheme",
              link: "/mutual-fund-scheme",
              guard: "mutualfundscheme",
            },
            {
              innerTitle: "Mutual Fund NAV",
              link: "/mutual-fund-nav",
              guard: "mutualfundnav",
            },
            {
              innerTitle: "Mutual Fund Menu",
              link: "/mutual-fund-menu",
              guard: "mutualfundmenu",
            },
            {
              innerTitle: "Latest Market Price",
              link: "/latest-market-price",
              guard: "latestmarketprice",
            },
          ],
        },
        {
          title: "MF Menu Contents",
          name: "mf-mutual-contents",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Detail Content",
              link: "/mf-detail-content",
              guard: "mfdetailcontent",
            },
            {
              innerTitle: "Listing Content",
              link: "/mf-listing-content",
              guard: "mflistingcontent",
            },
          ],
        },
        {
          title: "FAQ Section",
          name: "faq",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Faq Category",
              link: "faq-category",
              guard: "faqcategory",
            },
            {
              innerTitle: "FAQs",
              link: "faqs",
              guard: "faq"
            }
          ]
        },
        {
          title: "News & Notices",
          link: "/news-notices",
          hasSub: false,
          guard: "newsnotice",
        },
        {
          title: "Page",
          link: "/pages",
          hasSub: false,
          guard: "page",
        },
        {
          title: "Footer",
          name: "Footer",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Footer Link",
              link: "/footer-links",
              guard: "footerlink",
            },

            {
              innerTitle: "Office Information",
              link: "/office-information",
              guard: "footerlink",
            },
          ],
        },

        {
          title: "Users",
          link: "/users",
          hasSub: false,
          guard: "user",
        },
        {
          title: "Helpful Tools",
          link: "/helpful-tools",
          hasSub: false,
          guard: "helpfultool",
        },
        {
          title: "Complaints",
          link: "/complaints",
          hasSub: false,
          guard: "complaint",
        },
        {
          title : "Certificate Lost Notice",
          link : "/certificate-lost-notice",
          hasSub : false,
          guard : "complaint"
        },
        {
          title: "Activity Log",
          link: "/activity",
          hasSub: false,
          guard: "activity",
        },
      ],
    };
  },
  methods: {
    menuGuard(menu, hasSub = false) {
      if (menu.guard == "home") {
        return true;
      }
      if (hasSub == true) {
        let active = false;
        menu.subMenu.forEach((el) => {
          if (
            this.$store.getters.getPermissions.indexOf(
              el.guard + "." + "view"
            ) !== -1
          ) {
            active = true;
          }
        });
        return active;
      } else {
        return (
          this.$store.getters.getPermissions.indexOf(
            menu.guard + "." + "view"
          ) !== -1
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "../../assets/scss/main";
.sidebar-01-list>li.hasSub .card .active {
  color: rgb(255, 255, 255);
  font-weight: 600;
}
</style>
