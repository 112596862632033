<template>
    <flex-layout>
        <ProductInterestedListModal/>
        <page-header heading="Portfolio Interested List"/>
        <ProductInterestedListTable  :tableHeading="tableHeading"/>
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import ProductInterestedListTable from "@/components/ProductInterestedList/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import ProductInterestedListModal from "@/components/ProductInterestedList/modal.vue";
export default {
    name: "ProductInterestedList",
    components: {
        PageHeader,
        FlexLayout,
        ProductInterestedListTable,
        ProductInterestedListModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Name",
                },
                {
                    heading: "Contact",
                },
                {
                    heading: "Product",
                },
                {
                    heading: "Status",
                },
                {
                    heading:'Submitted on'
                }
            ],
        };
    },
   
};
</script>
  