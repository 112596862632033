<template>
    <flex-layout>
        <InvalidBankDetailsModal />
        <page-header heading="Invalid Bank Details" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm">
            <template v-slot:delete>
                <div>
                    <button class="btn btn-sm btn-outline-danger" @click="deleteAllData()"><i
                            class="ic-checklist"></i>Delete All</button>
                </div>
            </template>
        </page-header>
        <InvalidBankDetailsTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import InvalidBankDetailsTable from "@/components/InvalidBankDetails/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import InvalidBankDetailsModal from "@/components/InvalidBankDetails/modal.vue";
export default {
    name: "Menus",
    components: {
        PageHeader,
        FlexLayout,
        InvalidBankDetailsTable,
        InvalidBankDetailsModal
    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'invalid-bank-details',
            tableHeading: [
                {
                    heading: "Name",
                },
                {
                    heading: "File",
                },
                {
                    heading: "Description",
                },
                {
                    heading: "Image",
                },
                {
                    heading: "Status",
                },
                {
                    heading: "Order By",
                },
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
        deleteAllData() {

            let url = this.uri + "/delete-all";

            if (confirm("Do you really want to delete all data?")) {
                this.axios.delete(url)
                    .then((response) => {
                        this.$toast.success(response.data.message, {
                            position: "bottom-right",
                        });

                        this.$root.$emit('FETCH_INVALID_BANKs');

                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error("Error Deleting All data !!", {
                            position: "bottom-right",
                        });
                    });
            }
        }
    }
};
</script>
  