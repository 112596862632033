<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasEditGallery" tabindex="-1"
        aria-labelledby="offcanvasEditGallery">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasEditGallery">Edit Images</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
                    <div class="col-md-12 mb-16">
                        <label for="">Name</label>
                        <input type="text" class="form-control" v-model="item.name" />
                        <ErrorSpan :error="errors.name" />
                    </div>
                    <div class="col-md-12 mb-16">
                        <label for="">Album</label>
                        <select name="album_id" class="form-select" :class="{ 'border-red': errors.album_id }"
                            v-model="item.album_id">
                            <option value="" disabled selected>Select Album</option>
                            <option v-for="(cat, key) in albums" :key="key" :value="cat.id">{{ cat.name }}</option>
                        </select>
                        <ErrorSpan :error="errors.album_id" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Description</label>
                        <ckeditor v-model="item.description"></ckeditor>
                        <ErrorSpan :error="errors.description" />

                    </div>

                    <div class="row mb-16">
                        <div class="col-md-6 ">
                            <label for="">Image</label>
                            <input type="file" class="form-control" ref="file" @input="handleFileUpload" />
                            <ErrorSpan :error="errors.image" />
                        </div>
                        <div class="col-md-6 mt-16" v-if="upload.status == false">
                            <a v-if="item.image" :href="storage +  item.image" target="_blank">
                                <img :src="storage + item.image" alt="" height="50" width="50">
                            </a>
                        </div>
                        <div class="col-md-6 mt-16" v-else>
                            <img :src="upload.imgPreview" alt="" height="100" width="100">
                        </div>
                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">Display Order</label>
                        <input type="number" min="1" @input="item.order_by = item.order_by < 1 ? '' : item.order_by"
                            class="form-control" :class="{ 'border-red': errors.order_by }" v-model="item.order_by" />
                        <ErrorSpan :error="errors.order_by" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1"
                                v-model="item.status" />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
                        </div>
                    </div>

                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <button v-if="!isLoading" class="btn btn-green" type="submit">
                            <i class="ic-checkmark mr-8"></i>Save
                        </button>
                        <button v-else class="btn btn-green" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";
import CKEditor from 'ckeditor4-vue';
import UploadPreviewMixin from "@/mixins/uploadpreview.vue";


export default {
    name: "GalleryEditModal",
    mixins: [CreateMixin, UploadPreviewMixin],
    components: { ErrorSpan, ckeditor: CKEditor.component },
    props: ['albums'],
    data() {
        return {
            url: 'gallery',
            mode: "",
            fetch_module: 'FETCH_GALLERYs',
            item_struct: {
                id: "",
                name: "",
                description: "",
                image: "",
                order_by: 1,
                status: true,
                album_id: "",
            },
            image: [],
            imagePreview: [],
            filePreview: [],
        };
    },
    computed: {
    },
    mounted() {
        this.$root.$on('EDIT', (data, editMode) => {

            this.errors = {};
            this.editMode = editMode;
            this.isEdit = true;
            this.item = { ...data };
            this.editMode == true ? this.mode = "Edit" : this.mode = "View";
            this.imagePreview = [];
            this.image = this.item.image;
            this.clearSelectedFiles();
        });

    },
    methods: {
        handleFileUpload() {
            let image = this.$refs.file.files[0];
            this.upload.status = true;
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.upload.imgPreview = reader.result;
            }, false);

            if (image) {
                reader.readAsDataURL(image);
                this.item.image = image;
            }
        },
        clearSelectedFiles() {
            if (this.$refs.file) {
                this.$refs.file.value = null
                this.upload.status = false
            }
        },
    },
};
</script>
  
<style scoped></style>
  