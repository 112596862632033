<template>
    <flex-layout>
        <InvestorRelationCategoryModal :parents="parents" />
        <page-header heading="Investor Relation Category" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />

        <InvestorRelationCategoryTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import InvestorRelationCategoryTable from "@/components/InvestorRelationCategory/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import InvestorRelationCategoryModal from "@/components/InvestorRelationCategory/modal.vue";
export default {
    name: "InvestorRelationCategory",
    components: {
        PageHeader,
        FlexLayout,
        InvestorRelationCategoryTable,
        InvestorRelationCategoryModal
    },
    data() {
        return {
            parents: {},
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "Parent",
                },
                {
                    heading: "Status",
                }
            ],
        };
    },
    async created() {
        this.fetchParents();
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
        async fetchParents() {
            let url = process.env.VUE_APP_API_URL + 'investor-relation-category/parents';
            let { data } = await this.axios.get(url);
            this.parents = data.data;
        }
    }
};
</script>
  