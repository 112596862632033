<template>
    <flex-layout>
        <AdvisoryInterestedListModal/>
        <page-header heading="Advisory Interested List"/>
        <AdvisoryInterestedListTable  :tableHeading="tableHeading"/>
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import AdvisoryInterestedListTable from "@/components/AdvisoryInterestedList/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import AdvisoryInterestedListModal from "@/components/AdvisoryInterestedList/modal.vue";
export default {
    name: "AdvisoryInterestedList",
    components: {
        PageHeader,
        FlexLayout,
        AdvisoryInterestedListTable,
        AdvisoryInterestedListModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Name",
                },
                {
                    heading: "Contact",
                },
                {
                    heading: "Product",
                },
                {
                    heading: "Status",
                },
                {
                    heading:'Submitted on'
                }
            ],
        };
    },
   
};
</script>
  