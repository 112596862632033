<template>
    <flex-layout>
        <UncollectedCashDividendCheckModal :companies="companies" />
        <UncollectedCashDividendCheckDataModal :companies="companies"/>
        <page-header heading="Uncollected Cash Dividend Check" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm">
            <template v-slot:delete>
                <div>
                    <button class="btn btn-sm btn-outline-danger" @click="deleteAllData()"><i
                            class="ic-checklist"></i>Delete All</button>
                </div>
            </template>
            <template v-slot:example>
                <vue-excel-xlsx class="btn btn-sm btn-outline-yellow" :data="uncollected_cash_dividend_check.data"
                :columns="uncollected_cash_dividend_check.columns" file-name="uncollectedcashdividendchecktemplate" :file-type="'xlsx'"
                sheet-name="UncollectedCheckTemplate">
                <i class="ic-download" /> Template
            </vue-excel-xlsx>
            </template>
            <template v-slot:export>
            <a  :href="uri+'/export'">
                <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
            </a>
            </template>

        </page-header>

        <UncollectedCashDividendCheckTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import UncollectedCashDividendCheckTable from "@/components/UncollectedCashDividendCheck/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import UncollectedCashDividendCheckModal from "@/components/UncollectedCashDividendCheck/modal.vue";
import UncollectedCashDividendCheckDataModal from "@/components/UncollectedCashDividendCheck/datamodal.vue";

export default {
    name: "UncollectedCashDividendCheck",
    components: {
        PageHeader,
        FlexLayout,
        UncollectedCashDividendCheckTable,
        UncollectedCashDividendCheckModal,
        UncollectedCashDividendCheckDataModal
    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'uncollected-cash-dividend-check',
            companies: {},
            tableHeading: [
                {
                    heading: "Company",
                },
                {
                    heading: "BOID",
                },
                {
                    heading: "Total Kitta",
                },
                {
                    heading: "Dividend Amount",
                },
                {
                    heading: "Tax Amount",
                },
                {
                    heading: "Net Dividend Amount",
                },
                {
                    heading: "Fiscal Year",
                },
                {
                    heading: "File",
                },
                {
                    heading: "Remarks",
                }
            ],

            uncollected_cash_dividend_check: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "total_kitta",
                        field: "total_kitta",
                    },
                    {
                        label: "tax_amt",
                        field: "tax_amt",
                    },
                    {
                        label: "net_div_amt",
                        field: "net_div_amt",
                    },
                    {
                        label: "dividend_amt",
                        field: "dividend_amt",
                    },
                    {
                        label: "file",
                        field: "file",
                    },
                    {
                        label: "remarks",
                        field: "remarks",
                    }
                ],
                data: [
                    {
                        boid: '1111111111111111',
                        total_kitta: 10,
                        tax_amt: 20,
                        net_div_amt: 255,
                        dividend_amt:55152,
                        file:'file',
                        remarks:'remarks',
                    },

                ],


            },
        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL + 'uncollected-cash-dividend-check/getFields';
        let response = await this.axios.get(url);
        this.companies = response.data.companies;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },

        deleteAllData() {

            let url = this.uri + "/delete-all";

            if (confirm("Do you really want to delete all data?")) {
                this.axios.delete(url)
                    .then((response) => {
                        this.$toast.success(response.data.message, {
                            position: "bottom-right",
                        });

                        this.$root.$emit('FETCH_UNCOLLECTED_CASHs');

                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error("Error Deleting All data !!", {
                            position: "bottom-right",
                        });
                    });
            }
        }
    }
};
</script>
  <!--  -->