<template>
  <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
    aria-labelledby="offcanvasCreateLabel">
    <div class="offcanvas-header">
      <h5 class="text-dark" id="offcanvasCreateLabel">
        {{ mode }} Product/Service
      </h5>
      <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close" ref="btnClose">
        <i class="ic-close"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="col-md-6 mb-16">
            <label for="">Category</label>
            <select name="category_id" class="form-select" :class="{ 'border-red': errors.category_id }"
              v-model="item.category_id">
              <option value="" disabled selected>Select Parent</option>
              <option v-for="(cat, key) in categories" :key="key" :value="key">
                {{ cat }}
              </option>
            </select>
            <span v-if="errors.category_id" class="text-danger mt-2 font-size-13">{{ errors.category_id }}</span>
          </div>
          <div class="col-md-6 mb-16">
            <label for="">Name</label>
            <input type="text" class="form-control" :class="{ 'border-red': errors.name }" v-model="item.name"
              @input="createSlug" />
            <span v-if="errors.name" class="text-danger mt-2 font-size-13">{{
              errors.name
            }}</span>
          </div>
          <!-- <div class="col-md-12 mb-16">
                <label for="">Textarea</label>
                <textarea
                    class="form-control"
                    :class="{'border-red':errors.summary}"
                    v-model="item.summary"
                ></textarea>
                <span v-if="errors.summary" class="text-danger mt-2 font-size-13">{{errors.summary}}</span>
            </div> -->

          <div class="col-md-6 mb-16">
            <label for="">Slug</label>
            <input type="text" class="form-control" v-model="item.slug" disabled />
            <ErrorSpan :error="errors.slug" />
          </div>

          <div class="col-md-6 mb-16">
            <label for="">Parent</label>
            <select name="parent_id" class="form-select" :class="{ 'border-red': errors.parent_id }"
              v-model="item.parent_id">
              <option value="" selected>Select Parent</option>
              <option v-for="(cat, key) in parents" :key="key" :value="cat.id"> {{ cat.name }} </option>
            </select>
            <span v-if="errors.parent_id" class="text-danger mt-2 font-size-13">{{ errors.parent_id }}</span>
          </div>

          <div class="row mb-16">
            <div class="col-md-6 ">
              <label for="">Image</label>
              <input type="file" class="form-control" ref="file" @input="handleFileUpload" />
              <ErrorSpan :error="errors.image" />
            </div>
            <div class="col-md-6 mt-16" v-if="upload.status == false">
              <a v-if="item.image" :href="storage + item.image" target="_blank">
                <img :src="storage + item.image" alt="" height="50" width="50">
              </a>
            </div>
            <div class="col-md-6 mt-16" v-else>
              <img :src="upload.imgPreview" alt="" height="100" width="100">
            </div>
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Summary</label>
            <ckeditor v-model="item.summary" :config="editorConfig"></ckeditor>
            <span v-if="errors.summary" class="text-danger mt-2 font-size-13">{{
              errors.summary
            }}</span>
          </div>


          <div class="col-md-12 mb-16">
            <label for="">Placeholder</label>
            <textarea class="form-control" v-model="item.placeholder" rows="2" />
            <span v-if="errors.placeholder" class="text-danger mt-2 font-size-13">{{
              errors.placeholder
            }}</span>
          </div>




          <div class="col-md-6 mb-16">
            <label for="">Link</label>
            <input type="text" class="form-control" :class="{ 'border-red': errors.link }" v-model="item.link" />
            <span v-if="errors.link" class="text-danger mt-2 font-size-13">{{
              errors.link
            }}</span>
          </div>



          <div class="col-md-6 mb-16">
            <label for="">Icon</label>
            <input type="text" class="form-control" :class="{ 'border-red': errors.icon }" v-model="item.icon" />
            <span v-if="errors.icon" class="text-danger mt-2 font-size-13">{{
              errors.icon
            }}</span>
          </div>

          <div class="col-md-6 mb-16">
            <label for="">Display Order</label>
            <input type="text" class="form-control" :class="{ 'border-red': errors.order_by }" v-model="item.order_by" />
            <span v-if="errors.order_by" class="text-danger mt-2 font-size-13">{{ errors.order_by }}</span>
          </div>


          <div class="col-md-6 mb-16">
            <label for="">Display Type</label>
            <select name="type" class="form-select" :class="{ 'border-red': errors.display_type }"
              v-model="item.display_type">
              <option value="" selected>Select Type</option>
              <option value="1">Accordion</option>
              <option value="2">Plain</option>
            </select>
            <ErrorSpan :error="errors.display_type" />
          </div>

          <div class="col-md-6 mb-16">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" true-value="1" false-value="0"
                v-model="item.is_a_service" />
              <label class="form-check-label" for="flexSwitchCheckDefault">Is a Service?</label>
            </div>
          </div>


          <div class="col-md-6 mb-16">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="item.status" />
              <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
            </div>
          </div>
          <div class="col-md-12">
            <button type="button" aria-label="Close" @click="closeForm" class="btn btn-red mr-16">
              <i class="ic-close mr-8"></i>Cancel
            </button>
            <button v-if="!isLoading" class="btn btn-green" type="submit">
              <i class="ic-checkmark mr-8"></i>Save
            </button>
            <button v-else class="btn btn-green" type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CreateMixin from "../../mixins/create.vue";
import CKEditor from "ckeditor4-vue";
import ErrorSpan from "@/components/errorspan.vue";
import UploadPreviewMixin from "@/mixins/uploadpreview.vue";

export default {
  name: "CreateMenu",
  components: {
    ErrorSpan,
    ckeditor: CKEditor.component,
  },
  mixins: [CreateMixin, UploadPreviewMixin],
  props: ['parents'],

  data() {
    return {
      url: "product-service",
      categories: {},
      mode: "",
      fetch_module: "FETCH_PRODUCTs",
      editorConfig: {
        allowedContent: true
      }
    };
  },
  created() {
    this.axios
      .get(process.env.VUE_APP_API_URL + "product-service-category/get")
      .then((response) => {
        this.categories = response.data;
      });

    this.$root.$on("CREATE", () => {
      this.isEdit = false;
      this.item = {
        status: false,
      };
      this.errors = {};
      this.mode = "Add";
      this.clearSelectedFiles();
    });

    this.$root.$on("EDIT", (data) => {
      this.errors = {};
      this.isEdit = true;
      let uri = process.env.VUE_APP_API_URL + "product-service/edit/" + data.id;
      this.axios
        .get(uri)
        .then((response) => {
          this.item = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.mode = "Edit";
      this.clearSelectedFiles();
    });
  },
  methods: {
    closeForm() {
      this.$refs.btnClose.click();
    },
    createSlug() {
      this.item.slug = this.item.name.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-').replace(/^-+/, '').replace(/-+$/, '');
    },
    handleFileUpload() {
            let image = this.$refs.file.files[0];
            this.upload.status = true;
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.upload.imgPreview = reader.result;
            }, false);

            if (image) {
                reader.readAsDataURL(image);
                this.item.image = image;
            }
        },
        clearSelectedFiles() {
            if (this.$refs.file) {
                this.$refs.file.value = null
                this.upload.status = false
            }
        },
  },
};
</script>
