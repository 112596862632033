import Activity from "@/views/Activity/index.vue";
import AdvisoryInterestedList from "@/views/AdvisoryInteresedList/index.vue";
import Album from "@/views/Album/index.vue";
import AmlCompliance from "@/views/AmlCompliance/index.vue";
import BalanceFund from "@/views/BalanceFund/index.vue";
import CapitalStructure from "@/views/CapitalStructure/index.vue";
import CareerApplications from "@/views/CareerApplications/index.vue";
import CareerOpenings from "@/views/CareerOpenings/index.vue";
import CashDividendCheck from "@/views/CashDividendCheck/index.vue";
import CertificateaLostNotice from "@/views/CertificateLostNotice/index.vue";
import Company from "@/views/Company/index.vue";
import Complaint from "@/views/Complaint/index.vue";
import CorporateProfile from "@/views/CorporateProfile/index.vue";
import DebentureInterestCheck from "@/views/DebentureInterestCheck/index.vue";
import Department from "@/views/Department/index.vue";
import DepartmentType from "@/views/DepartmentType/index.vue";
import FaqCategory from "@/views/FaqCategory/index.vue";
import FAQs from "@/views/FAQs/index.vue";
import FinalCallCheck from "@/views/FinalCallCheck/index.vue";
import FooterLink from "@/views/FooterLink/index.vue";
import Gallery from "@/views/Gallery/index.vue";
import HelpfulTool from "@/views/HelpfulTool/index.vue";
import InterestedList from "@/views/InterestedList/index.vue";
import InvalidBankDetails from "@/views/InvalidBankDetails/index.vue";
import InvestorRelation from "@/views/InvestorRelation/index.vue";
import InvestorRelationCategory from "@/views/InvestorRelationCategory/index.vue";
import LatestMarketPrice from "@/views/LatestMarketPrice/index.vue";
import LoginLink from "@/views/LoginLink/index.vue";
import Member from "@/views/Member/index.vue";
import MFDetailContent from "@/views/MutualFund/Detail/index.vue";
import MFListingContent from "@/views/MutualFund/Listing/index.vue";
import MutualFundMenu from "@/views/MutualFund/Menu/index.vue";
import MutualFundNav from "@/views/MutualFund/NAV/index.vue";
import MutualFundScheme from "@/views/MutualFund/Scheme/index.vue";
import Office from "@/views/Office/index.vue";
import OfficeInformation from "@/views/OfficeInformation/index.vue";
import OpenCareer from "@/views/OpenCareer/index.vue";
import ProductShowCase from "@/views/ProductShowCase/index.vue";
import RefundDetailCheck from "@/views/RefundDetailCheck/index.vue";
import Registrar from "@/views/Registrar/index.vue";
import RejectedDRNListCheck from "@/views/RejectedDRNListCheck/index.vue";
import RightIPOValidity from "@/views/RightIPOValidity/index.vue";
import RightShareAllotment from "@/views/RightShareAllotment/index.vue";
import RightShareEligibilityCheck from "@/views/RightShareEligibilityCheck/index.vue";
import RoleSetup from "@/views/RoleSetup/index.vue";
import ShareAllotmentCheck from "@/views/ShareAllotmentCheck/index.vue";
import TaxLiabilityCheck from "@/views/TaxLiabilityCheck/index.vue";
import UncollectedCashDividendCheck from "@/views/UncollectedCashDividendCheck/index.vue";
import Users from "@/views/User/index.vue";
import UserRole from "@/views/UserRole/index.vue";
import WaccCheck from "@/views/WaccCheck/index.vue";
import axios from "axios";
import Vue from "vue";
import VueAxios from "vue-axios";
import Router from "vue-router";
import Carousel from "../views/Carousel/index.vue";
import Main from "../views/Dashboard/index.vue";
import MainDashboard from "../views/Dashboard/MainDashboard.vue";
import Download from "../views/Download/index.vue";
import DownloadCategory from "../views/DownloadCategory/index.vue";
import FormElement from "../views/FormElement/index.vue";
import Login from "../views/Login/Login.vue";
import ManagePopups from "../views/ManagePopups/index.vue";
import MasterConfig from "../views/MasterConfig/index.vue";
import Menus from "../views/Menus/index.vue";
import NewsNotice from "../views/NewsNotice/index.vue";
import Page from "../views/Page/index.vue";
import ProductServices from "../views/ProductServices/index.vue";
import ProductServicesCategory from "../views/ProductServicesCategory/index.vue";

import { store } from "../store.js";

Vue.use(Router);
Vue.use(VueAxios, axios);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthorized = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (to.meta.guard == "home") {
      next();
    } else if (
      store.getters.getPermissions.indexOf(to.meta.guard + "." + "view") !== -1
    ) {
      next();
    } else {
      next({ path: "", replace: true });
    }
  } else {
    return next("/login");
  }
};

const router = new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      component: Main,
      children: [
        {
          path: "",
          name: "MainDashboard",
          component: MainDashboard,
          beforeEnter: ifAuthorized,
          meta: { guard: "home" },
        },
        {
          path: "master-config",
          name: "MasterConfig",
          component: MasterConfig,
          beforeEnter: ifAuthorized,
        },
        {
          path: "form-element",
          name: "FormElement",
          component: FormElement,
          beforeEnter: ifAuthorized,
        },
        {
          path: "manage-popups",
          name: "ManagePopups",
          component: ManagePopups,
          meta: { guard: "popup" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "menus",
          name: "Menus",
          component: Menus,
          meta: { guard: "menu" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "product-services-category",
          name: "ProductServicesCategory",
          component: ProductServicesCategory,
          beforeEnter: ifAuthorized,
          meta: { guard: "productservicecategory" },
        },
        {
          path: "product-services",
          name: "ProductServices",
          component: ProductServices,
          beforeEnter: ifAuthorized,
          meta: { guard: "productservice" },
        },
        {
          path: "download-category",
          name: "DownloadCategory",
          component: DownloadCategory,
          meta: { guard: "downloadcategory" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "download",
          name: "Download",
          component: Download,
          meta: { guard: "download" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "pages",
          name: "Pages",
          component: Page,
          beforeEnter: ifAuthorized,
          meta: { guard: "page" },
        },
        {
          path: "news-notices",
          name: "NewsNotice",
          component: NewsNotice,
          beforeEnter: ifAuthorized,
          meta: { guard: "newsnotice" },
        },
        {
          path: "carousel",
          name: "Carousel",
          component: Carousel,
          beforeEnter: ifAuthorized,
          meta: { guard: "carousel" },
        },
        {
          path: "office",
          name: "Office",
          component: Office,
          beforeEnter: ifAuthorized,
          meta: { guard: "office" },
        },

        {
          path: "/openings",
          name: "Openings",
          component: CareerOpenings,
          beforeEnter: ifAuthorized,
          meta: { guard: "careeropening" },
        },
        {
          path: "/applications",
          name: "Applications",
          component: CareerApplications,
          beforeEnter: ifAuthorized,
          meta: { guard: "careerapplication" },
        },

        {
          path: "/faqs",
          name: "FAQs",
          component: FAQs,
          beforeEnter: ifAuthorized,
          meta: { guard: "faq" },
        },
        {
          path: "/member",
          name: "Member",
          component: Member,
          beforeEnter: ifAuthorized,
          meta: { guard: "member" },
        },
        {
          path: "/capital-structure",
          name: "Capital Structure",
          component: CapitalStructure,
          beforeEnter: ifAuthorized,
          meta: { guard: "capitalstructure" },
        },
        {
          path: "/investor-relation-category",
          name: "Investor Relation Category",
          component: InvestorRelationCategory,
          beforeEnter: ifAuthorized,
          meta: { guard: "investorrelationcategory" },
        },
        {
          path: "/investor-relation",
          name: "Investor Relation",
          component: InvestorRelation,
          beforeEnter: ifAuthorized,
          meta: { guard: "investorrelation" },
        },
        {
          path: "/album",
          name: "Album",
          component: Album,
          beforeEnter: ifAuthorized,
          meta: { guard: "album" },
        },
        {
          path: "/gallery",
          name: "Gallery",
          component: Gallery,
          meta: { guard: "gallery" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "/company",
          name: "Company",
          component: Company,
          beforeEnter: ifAuthorized,
          meta: { guard: "company" },
        },
        {
          path: "/login-link",
          name: "LoginLink",
          component: LoginLink,
          beforeEnter: ifAuthorized,
          meta: { guard: "loginlink" },
        },
        {
          path: "/mutual-fund-nav",
          name: "MutualFundNAV",
          component: MutualFundNav,
          beforeEnter: ifAuthorized,
          meta: { guard: "mutualfundnav" },
        },
        {
          path: "/mutual-fund-menu",
          name: "MutualFundMenu",
          component: MutualFundMenu,
          beforeEnter: ifAuthorized,
          meta: { guard: "mutualfundmenu" },
        },
        {
          path: "/mutual-fund-scheme",
          name: "MutualFundScheme",
          component: MutualFundScheme,
          beforeEnter: ifAuthorized,
          meta: { guard: "mutualfundscheme" },
        },
        {
          path: "/mf-listing-content",
          name: "MFListingContent",
          component: MFListingContent,
          beforeEnter: ifAuthorized,
          meta: { guard: "mflistingcontent" },
        },

        {
          path: "/mf-detail-content",
          name: "MFDetailContent",
          component: MFDetailContent,
          beforeEnter: ifAuthorized,
          meta: { guard: "mfdetailcontent" },
        },
        {
          path: "/tax-liability-check",
          name: "TaxLiabilityCheck",
          component: TaxLiabilityCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "taxliabilitycheck" },
        },
        {
          path: "/right-share-eligibility-check",
          name: "RightShareEligibilityCheck",
          component: RightShareEligibilityCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "rightshareeligibilitycheck" },
        },
        {
          path: "/rejected-drn-list-check",
          name: "RejectedDRNListCheck",
          component: RejectedDRNListCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "rejecteddrnlistcheck" },
        },
        {
          path: "/right-share-allotment",
          name: "RightShareAllotment",
          component: RightShareAllotment,
          beforeEnter: ifAuthorized,
          meta: { guard: "rightshareallotment" },
        },
        {
          path: "/cash-dividend-check",
          name: "CashDividendCheck",
          component: CashDividendCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "cashdividendcheck" },
        },
        {
          path: "/invalid-bank-details",
          name: "InvalidBankDetails",
          component: InvalidBankDetails,
          beforeEnter: ifAuthorized,
          meta: { guard: "invalidbankdetails" },
        },
        {
          path: "/share-allotment-check",
          name: "ShareAllotmentCheck",
          component: ShareAllotmentCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "shareallotmentcheck" },
        },
        {
          path: "/right-ipo-validity",
          name: "RightIPOValidity",
          component: RightIPOValidity,
          beforeEnter: ifAuthorized,
          meta: { guard: "rightipovalidity" },
        },
        {
          path: "/footer-links",
          name: "FooterLink",
          component: FooterLink,
          beforeEnter: ifAuthorized,
          meta: { guard: "footerlink" },
        },
        {
          path: "/users",
          name: "Users",
          component: Users,
          beforeEnter: ifAuthorized,
          meta: { guard: "user" },
        },
        {
          path: "/balancefund",
          name: "BalanceFund",
          component: BalanceFund,
          beforeEnter: ifAuthorized,
          meta: { guard: "balancefund" },
        },
        {
          path: "/activity",
          name: "Activity",
          component: Activity,
          beforeEnter: ifAuthorized,
          meta: { guard: "activity" },
        },
        {
          path: "/role-setup",
          name: "RoleSetup",
          component: RoleSetup,
          beforeEnter: ifAuthorized,
          meta: { guard: "user" },
        },
        {
          path: "/user-role",
          name: "UserRole",
          component: UserRole,
          beforeEnter: ifAuthorized,
          meta: { guard: "user" },
        },
        {
          path: "/corporate-profile",
          name: "CorporateProfile",
          component: CorporateProfile,
          beforeEnter: ifAuthorized,
          meta: { guard: "corporateprofile" },
        },
        {
          path: "/office-information",
          name: "OfficeInformation",
          component: OfficeInformation,
          beforeEnter: ifAuthorized,
          meta: { guard: "corporateprofile" },
        },
        {
          path: "/opencareer",
          name: "OpenCareer",
          component: OpenCareer,
          beforeEnter: ifAuthorized,
          meta: { guard: "opencareers" },
        },
        {
          path: "/complaints",
          name: "Complaints",
          component: Complaint,
          beforeEnter: ifAuthorized,
          meta: { guard: "complaint" },
        },
        {
          path : "/certificate-lost-notice",
          name : "CertificateLostNotice",
          component : CertificateaLostNotice,
          beforeEnter : ifAuthorized,
          meta : { guard : "complaint" }
        },
        {
          path: "/helpful-tools",
          name: "HelpfulTool",
          component: HelpfulTool,
          beforeEnter: ifAuthorized,
          meta: { guard: "helpfultool" },
        },
        {
          path: "/aml-compliance",
          name: "AmlCompliance",
          component: AmlCompliance,
          beforeEnter: ifAuthorized,
          meta: { guard: "amlcompliance" },
        },
        {
          path: "/refund-detail-check",
          name: "RefundDetailCheck",
          component: RefundDetailCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "refunddetailcheck" },
        },
        {
          path: "/debenture-interest-check",
          name: "DebentureInterestCheck",
          component: DebentureInterestCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "debentureinterestcheck" },
        },
        {
          path: "/uncollected-cash-dividend-check",
          name: "UncollectedCashDividendCheck",
          component: UncollectedCashDividendCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "uncollectedcashdividend" },
        },
        {
          path: "/final-call-check",
          name: "FinalCallCheck",
          component: FinalCallCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "finalcallcheck" },
        },
        {
          path: "/product-showcase",
          name: "ProductShowcase",
          component: ProductShowCase,
          beforeEnter: ifAuthorized,
          meta: { guard: "productshowcase" },
        },
        {
          path: "/interested-list",
          name: "InterestedList",
          component: InterestedList,
          beforeEnter: ifAuthorized,
          meta: { guard: "productinterestedlist" },
        },
        {
          path: "/registrar",
          name: "Registrar",
          component: Registrar,
          beforeEnter: ifAuthorized,
          meta: { guard: "registrar" },
        },
        {
          path: "/department",
          name: "Department",
          component: Department,
          beforeEnter: ifAuthorized,
          meta: { guard: "department" },
        },
        {
          path: "/department-type",
          name: "Department Type",
          component: DepartmentType,
          beforeEnter: ifAuthorized,
          meta: { guard: "departmenttype" },
        },
        {
          path: "/latest-market-price",
          name: "Latest Market Price",
          component: LatestMarketPrice,
          beforeEnter: ifAuthorized,
          meta: { guard: "latestmarketprice"},
        },
        {
          path: "/advisory-interested-list",
          name: "Advisory Interested List",
          component: AdvisoryInterestedList,
          beforeEnter: ifAuthorized,
          meta: { guard: "advisoryinterestedlist"},
        },
        {
          path: "/faq-category",
          name: "FAQ Category",
          component: FaqCategory,
          beforeEnter: ifAuthorized,
          meta: { guard: "faqcategory"},
        },
        {
          path: "/wacc-check",
          name: "WaccCheck",
          component: WaccCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "wacccheck" },
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      beforeEnter: ifNotAuthenticated,
    },
    { path: "*", redirect: "/" },
  ],
});

export default router;
