<template>
  <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
    aria-labelledby="offcanvasCreateLabel">
    <div class="offcanvas-header">
      <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Download</h5>
      <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close" ref="btnClose">
        <i class="ic-close"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="col-md-12 mb-16">
            <label for="">Title</label>
            <input type="text" class="form-control" :class="{ 'border-red': errors.title }" v-model="item.title" @input="createSlug()"/>
            <span v-if="errors.title" class="text-danger mt-2 font-size-13">{{ errors.title }}</span>
          </div>
          <div class="col-md-12 mb-16">
            <label for="">Slug</label>
            <input type="text" class="form-control" v-model="item.slug" />
            <ErrorSpan :error="errors.slug" />
          </div>
          <div class="col-md-6 mb-16">
            <label for="">Category</label>
            <select name="category_id" class="form-select" :class="{ 'border-red': errors.category_id }"
              v-model="item.category_id">
              <option value="" disabled selected>Select Parent</option>
              <option v-for="cat, key in categories" :key="key" :value="key">{{ cat }}</option>
            </select>
            <span v-if="errors.category_id" class="text-danger mt-2 font-size-13">{{ errors.category_id }}</span>
          </div>
          <!-- <div class="col-md-6 mb-16">
              <label for="">Link</label>
              <input type="text" class="form-control" :class="{'border-red':errors.link}" v-model="item.link" />
              <span v-if="errors.link" class="text-danger mt-2 font-size-13">{{errors.link}}</span>
            </div> -->
          <div class="col-md-6 mb-16">
            <label for="">Display Order</label>
            <input type="number" min="1" @input="item.order_by = item.order_by < 1 ? '' : item.order_by"
              class="form-control" :class="{ 'border-red': errors.order_by }" v-model="item.order_by" />
            <span v-if="errors.order_by" class="text-danger font-size-13">{{ errors.order_by }}</span>
          </div>

          <div class="row mb-16">
            <div class="col-md-6 ">
              <label for="">File</label>
              <UploadedFile :file='storage + item.file' :clearUploadedFile="clearUploadedFile" :type="file"
                v-if="mode === 'Edit' && item.file" />
              <input type="file" class="form-control" ref="file" @input="handleFileUpload" v-else />

            </div>
            <ErrorSpan :error="errors.file" />
          </div>

          <div class="col-md-6 mb-16">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="item.status" />
              <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
            </div>
          </div>
          <div class="col-md-12">
            <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
              <i class="ic-close mr-8"></i>Cancel
            </button>
            <button v-if="!isLoading" class="btn btn-green" type="submit">
              <i class="ic-checkmark mr-8"></i>Save
            </button>
            <button v-else class="btn btn-green" type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UploadedFile from "@/components/Utility/UploadedFile.vue";
import ErrorSpan from "@/components/errorspan.vue";
import UploadPreviewMixin from "@/mixins/uploadpreview.vue";
import CreateMixin from "../../mixins/create.vue";




export default {
  name: "Create",
  components: {
    UploadedFile, ErrorSpan
  },
  mixins: [CreateMixin, UploadPreviewMixin],

  data() {
    return {
      url: 'download',
      categories: {},
      mode: "",
      fetch_module: 'FETCH_DOWNLOADs'
    };
  },
  mounted() {
    this.axios.get(process.env.VUE_APP_API_URL + 'download-category/get').then((response) => {
      this.categories = response.data;
    });
    this.$root.$on('CREATE', () => {
      this.isEdit = false;
      this.item = {
      };
      this.errors = {};
      this.mode = "Add";
      this.clearSelectedFiles();
    });

    this.$root.$on('EDIT', (data) => {
      this.isEdit = true;
      this.item = { ...data };
      this.errors = {};
      this.mode = "Edit";
      this.clearSelectedFiles();
    });

  },
  methods: {
    handleFileUpload() {
      let file = this.$refs.file.files[0];
      this.upload.status = true;
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.upload.imgPreview = reader.result;
      }, false);

      if (file) {
        reader.readAsDataURL(file);
        this.item.file = file;
      }
    },
    clearSelectedFiles() {
      if (this.$refs.file) {
        this.$refs.file.value = null
        this.upload.status = false
      }
    },
    clearUploadedFile() {
      this.item.file = null;
    },
    createSlug() {
      this.item.slug = this.item.title
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-")
        .replace(/^-+/, "")
        .replace(/-+$/, "");
    },
  },
};
</script>
