<template>
    <flex-layout>
        <CapitalStructureModal />
        <page-header v-if="dataCount == 0" heading="Capital Structure" action="Add" toggle="offcanvas"
            target="#offcanvasCreate" @click="setCreateForm" :guard="this.$route.meta.guard"/>
        <page-header v-else heading="Capital Structure" />
        <CapitalStructureTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import { eventbus } from "../../plugins/eventbus";
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import CapitalStructureTable from "@/components/CapitalStructure/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import CapitalStructureModal from "@/components/CapitalStructure/modal.vue";
export default {
    name: "CapitalStructure",
    components: {
        // AbsoluteLayout,
        PageHeader,
        FlexLayout,
        CapitalStructureTable,
        CapitalStructureModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "SubTitle",
                },
                {
                    heading: "Status",
                },
            ],

            dataCount: 0
        };
    },
    mounted() {
        eventbus.$on('capital-structure-count', (data) => {
            this.dataCount = data;
        });
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  