<template>
    <flex-layout>
        <FaqCategoryModal/>
        <page-header heading="FAQ Categories" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />
        <FaqCategoryTable  :tableHeading="tableHeading"/>
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import FaqCategoryTable from "@/components/FaqCategory/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import FaqCategoryModal from "@/components/FaqCategory/modal.vue";
export default {
    name: "FaqCategory",
    components: {
        PageHeader,
        FlexLayout,
        FaqCategoryTable,
        FaqCategoryModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Name",
                },
                {
                    heading: "Description",
                },
                {
                    heading: "Faq Count"
                },
                {
                    heading: "Status",
                },
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  