<template>
  <flex-layout>
    <Create />
    <page-header heading="Pages" action="Add" 
                toggle="offcanvas"
                target="#offcanvasCreate"
                @click="setCreateForm"
                :guard="this.$route.meta.guard"
    />
    
    <Table :tableHeading="tableHeading"/>
  </flex-layout>
</template>

<script>
  import FlexLayout from "../../components/Layout/FlexLayout.vue";
  import Create from "./create.vue";
  import Table from "./table.vue";
  import PageHeader from "../../components/Utility/PageHeader.vue";
  export default {
    name: "Page",
    components: {
      PageHeader,
      FlexLayout,
      Create,
      Table
    },
    data() {
      return {
        tableHeading: [
          {
            heading: "Title",
          },
          {
            heading: "Slug",
          },
          {
            heading: "Image",
          },
          {
            heading: "Status",
          },
        ],
      };
    },
   created() {

    },
    methods: {
     
      setCreateForm(){
        this.$root.$emit('CREATE');
      },
    }
  };
</script>
