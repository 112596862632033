<template>
    <flex-layout>
        <RejectedDRNListCheckModal :companies="companies" />
        <RejectedDRNListCheckDataModal :companies="companies" />
        <page-header heading="Rejected DRN List Check" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm">

            <template v-slot:delete>
                <div>
                    <button class="btn btn-sm btn-outline-danger" @click="deleteAllData()"><i
                            class="ic-checklist"></i>Delete All</button>
                </div>
            </template>

            <template v-slot:example>
            <vue-excel-xlsx class="btn btn-sm btn-outline-yellow" :data="rejected_drn_list.data"
                :columns="rejected_drn_list.columns" file-name="rejecteddrnlisttemplate" :file-type="'xlsx'"
                sheet-name="RejectedDRNListTemplate">
                <i class="ic-download"/> Template
            </vue-excel-xlsx>
            </template>
            <template v-slot:export>
            <a :href="uri+'/export'">
                <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
            </a>
            </template>
        </page-header>

        <RejectedDRNListCheckTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import RejectedDRNListCheckTable from "@/components/RejectedDRNListCheck/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import RejectedDRNListCheckModal from "@/components/RejectedDRNListCheck/modal.vue";
import RejectedDRNListCheckDataModal from "@/components/RejectedDRNListCheck/datamodal.vue";

export default {
    name: "RejectedDRNListCheck",
    components: {
        PageHeader,
        FlexLayout,
        RejectedDRNListCheckTable,
        RejectedDRNListCheckModal,
        RejectedDRNListCheckDataModal
    },
    data() {
        return {
            companies: {},
            uri: process.env.VUE_APP_API_URL + 'rejected-drn-list-check',
            tableHeading: [
                {
                    heading: "DRN",
                },
                {
                    heading: "Name",
                },
                {
                    heading: "Company",
                },
                {
                    heading: "Kitta",
                },
                {
                    heading: "BOID",
                },
                {
                    heading: "Reason",
                },
            ],
            rejected_drn_list: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "drn",
                        field: "drn",
                    },      {
                        label: "name",
                        field: "name",
                    },
                    {
                        label: "kitta",
                        field: "kitta",
                    },
                    {
                        label: "remarks",
                        field: "remarks",
                    },

                ],
                data: [
                    {
                        boid: '1111111111111111',
                        drn:'111111',
                        name: 'FirstName LastName',
                        share_holder_no:'558893',
                        kitta: '10',
                        remarks:'remarks'
                    },

                ],


            },

        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL + 'rejected-drn-list-check/getCompanies';
        let response = await this.axios.get(url);
        this.companies = response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },

        deleteAllData() {

            let url = this.uri + "/delete-all";

            if (confirm("Do you really want to delete all data? Make sure you have backups")) {
                this.axios.delete(url)
                    .then((response) => {
                        this.$toast.success(response.data.message, {
                            position: "bottom-right",
                        });

                        this.$root.$emit('FETCH_REJECTED_DRNs');

                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error("Error Deleting All data !!", {
                            position: "bottom-right",
                        });
                    });
            }
        }
    }
};
</script>
  <!--  -->