<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Company</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
                    <div class="col-md-12 mb-16">
                        <label for="">Name</label>
                        <input type="text" class="form-control" v-model="item.name"  @input="createSlug"/>
                        <ErrorSpan :error="errors.name" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Slug</label>
                        <input type="text" disabled class="form-control" v-model="item.slug" />
                        <ErrorSpan :error="errors.slug" />
                    </div>



                    <div class="col-md-12 mb-16">
                        <label for="">Description</label>
                        <ckeditor v-model="item.description"></ckeditor>
                        <ErrorSpan :error="errors.description" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Type</label>
                        <select name="type" class="form-select" :class="{'border-red':errors.type}"
                            v-model="item.type">
                            {{ options}}
                            <option value="" disabled selected>Select Type</option>
                           
                            <option v-for="(item, index) in options" :key="index" :value="item.slug">{{ item.type }}</option>
                        </select>
                        <span v-if="errors.type" class="text-danger mt-2 font-size-13">{{errors.type}}</span>
                    </div>

                    <div class="col-md-6 mb-16">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1"
                                v-model="item.status" />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
                        </div>
                    </div>

                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <button v-if="!isLoading" class="btn btn-green" type="submit">
                            <i class="ic-checkmark mr-8"></i>Save
                        </button>
                        <button v-else class="btn btn-green" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";
import CKEditor from 'ckeditor4-vue';

export default {
    name: "CompanyModal",
    mixins: [CreateMixin],
    components: { ErrorSpan, ckeditor: CKEditor.component },
    data() {
        return {
            url: 'company',
            mode: "",
            fetch_module:'FETCH_COMPANYs',
            options: [
            {slug: "right-share-eligibility-check", type: "Right Share Eligibility Check"},
            {slug: "share-allotment-check", type: "Share Allotment Check"},
            {slug: "tax-liability-check", type: "Tax Liability Check"},
            {slug: "cash-dividend-check", type: "Cash Dividend Check"},
            {slug: "refund-detail-check", type: "Refund Detail Check"},
            {slug: "uncollected-cash-dividend-check", type: "Uncollected Cash Dividend Check"},
            {slug: "rejected-drn-check", type: "Rejected DRM Check"},
            {slug: "right-share-allotment-check", type: "Right Share Allottment Check"},
            {slug: "debenture-interest-check", type: "Debenture Interest Check"},
            {slug: "final-call-check", type: "Final Call Check"},
            {slug: "wacc-check", type: "WACC Calculation Check"},

        ]
        };
    },
    computed: {

    },
    mounted() {
        this.$root.$on('CREATE', () => {
            this.isEdit = false;
            this.item = {
            };
            this.errors = {};
            this.mode = "Add";
        });

        this.$root.$on('EDIT', (data) => {
            this.errors = {};
            this.isEdit = true;
            this.item = { ...data };
            this.mode = "Edit";
        });

    },
    methods: {
        createSlug() {
            this.item.slug = this.item.name.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-').replace(/^-+/, '').replace(/-+$/, '');
        },
    },
};
</script>
  
<style scoped>

</style>
  