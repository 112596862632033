<template>
    <flex-layout>
        <RightIPOValidityModal/>
        <page-header heading="Right Share and IPO/FPO Validity" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />

        <RightIPOValidityTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import RightIPOValidityTable from "@/components/RightIPOValidity/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import RightIPOValidityModal from "@/components/RightIPOValidity/modal.vue";
export default {
    name: "RightIPOValidity",
    components: {
        PageHeader,
        FlexLayout,
        RightIPOValidityTable,
        RightIPOValidityModal
    },
    data() {
        return {
            parents: {},
            tableHeading: [
                {
                    heading: "Type",
                },
                {
                    heading: "Company Name",
                },
                {
                    heading: "Date",
                },
                {
                    heading: "Status",
                }
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  