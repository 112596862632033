<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="cashdatamodal" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Refund Detail Check</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">

                    <div class="col-md-12 mb-16">
                        <label for="">BOID</label>
                        <input type="text" class="form-control" v-model="item.boid" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.boid" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Name</label>
                        <input type="text" class="form-control" v-model="item.name" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.name" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Share Holder No</label>
                        <input type="text" class="form-control" v-model="item.share_holder_no" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.share_holder_no" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Issue Type</label>
                        <select name="type" class="form-select" :class="{ 'border-red': errors.issue_type }"
                            v-model="item.issue_type" :readonly="!editMode">
                            <option value="" disabled selected>Select Issue Type</option>
                            <option value="1">Right</option>
                            <option value="2">Auction</option>
                            <option value="3">IPO</option>
                            <option value="4">Auction Promoter</option>
                        </select>
                        <ErrorSpan :error="errors.issue_type" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">App No</label>
                        <input type="text" class="form-control" v-model="item.app_no" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.app_no" />
                    </div>
                    
                    <div class="col-md-12 mb-16">
                        <label for="">All Kitta</label>
                        <input type="text" class="form-control" v-model="item.all_kitta" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.all_kitta" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">App Kitta</label>
                        <input type="text" class="form-control" v-model="item.app_kitta" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.app_kitta" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Deposit Amount</label>
                        <input type="text" class="form-control" v-model="item.deposit_amt" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.deposit_amt" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Refund Amount</label>
                        <input type="text" class="form-control" v-model="item.refund_amt" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.refund_amt" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Remarks</label>
                        <input type="text" class="form-control" v-model="item.remarks" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.remarks" />
                    </div>

                    <!-- <div class="col-md-12 mb-16">
                      <label for="">File</label>
                      <input type="text" class="form-control" v-model="item.file" />
                      <ErrorSpan :error="errors.total_tax" />
                  </div> -->

                    <div class="col-md-12 mb-16">
                        <label for="">Company</label>
                        <select name="company_id" class="form-select" :class="{ 'border-red': errors.company_id }"
                            v-model="item.company_id" :readonly="!editMode" :disabled="!editMode">
                            <option value="" disabled selected>Select Company</option>
                            <option v-for="(cat, key) in companies" :key="key" :value="cat.id">{{ cat.name }}</option>
                        </select>
                        <ErrorSpan :error="errors.company_id" />
                    </div>

                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <template v-if="editMode">
                            <button v-if="!isLoading" class="btn btn-green" type="submit">
                                <i class="ic-checkmark mr-8"></i>Save
                            </button>
                            <button v-else class="btn btn-green" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        </template>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";

export default {
    name: "RefundDetailCheckDataModal",
    mixins: [CreateMixin],
    components: { ErrorSpan },
    props: ['companies'],
    data() {
        return {
            url: 'refund-detail-check',
            mode: "",
            editMode: true,
            fetch_module: 'FETCH_REFUND_DETAIL_CHECK',
        };
    },
    computed: {

    },
    mounted() {

        this.$root.$on('EDIT', (data, editMode) => {
            this.editMode = editMode;
            this.isEdit = true;
            this.item = { ...data };
            this.editMode == true ? this.mode = "Edit" : this.mode = "View";
        });

    },
    methods: {

    },
};
</script>

<style scoped>

</style>
