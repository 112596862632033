<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="cashdatamodal" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Debenture Interest Data</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">

                    <div class="col-md-12 mb-16">
                        <label for="">BOID</label>
                        <input type="text" class="form-control" v-model="item.boid" :readonly="!editMode" :disabled="!editMode"/>
                        <ErrorSpan :error="errors.boid" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Debenture Holder Name</label>
                        <input type="text" class="form-control" v-model="item.debenture_holder_name" :readonly="!editMode" :disabled="!editMode"/>
                        <ErrorSpan :error="errors.debenture_holder_name" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Interest Amount</label>
                        <input type="text" class="form-control" v-model="item.interest_amt" :readonly="!editMode" :disabled="!editMode"/>
                        <ErrorSpan :error="errors.interest_amt" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Interest Tax</label>
                        <input type="text" class="form-control" v-model="item.interest_tax" :readonly="!editMode" :disabled="!editMode"/>
                        <ErrorSpan :error="errors.interest_tax" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Net Interest Amount</label>
                        <input type="text" class="form-control" v-model="item.net_interest_amt" :readonly="!editMode" :disabled="!editMode"/>
                        <ErrorSpan :error="errors.net_interest_amt" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Semi Quarter</label>
                        <input type="text" class="form-control" v-model="item.semi_quarter" :readonly="!editMode" :disabled="!editMode"/>
                        <ErrorSpan :error="errors.semi_quarter" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Remarks</label>
                        <input type="text" class="form-control" v-model="item.remarks" :readonly="!editMode" :disabled="!editMode"/>
                        <ErrorSpan :error="errors.remarks" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Fiscal Year</label>
                        <select name="type" class="form-select" :class="{ 'border-red': errors.fiscal_year }"
                            v-model="item.fiscal_year">
                            <option value="" disabled selected>Select Fiscal Year</option>
                            <option v-for="(item, index) in fiscal_year" :key="index" :value="item"> {{ item }}
                            </option>
                        </select>
                        <span v-if="errors.fiscal_year"
                            class="text-danger mt-2 font-size-13">{{ errors.fiscal_year }}</span>
                    </div>

                    <div class="row mb-16">
                        <div class="col-md-6 ">
                            <label for="">File</label>
                            <input type="file" class="form-control" ref="file" @input="handleFileUpload" />
                            <ErrorSpan :error="errors.file" />
                        </div>
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Company</label>
                        <select name="company_id" class="form-select" :class="{ 'border-red': errors.company_id }"
                            v-model="item.company_id" :readonly="!editMode" :disabled="!editMode">
                            <option value="" disabled selected>Select Company</option>
                            <option v-for="(cat, key) in companies" :key="key" :value="cat.id">{{ cat.name }}</option>
                        </select>
                        <ErrorSpan :error="errors.company_id" />
                    </div>

                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <template v-if="editMode">
                            <button v-if="!isLoading" class="btn btn-green" type="submit">
                                <i class="ic-checkmark mr-8"></i>Save
                            </button>
                            <button v-else class="btn btn-green" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        </template>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";
import UploadedFile from "@/components/Utility/UploadedFile.vue";

export default {
    name: "DebentureInterestCheckDataModal",
    mixins: [CreateMixin ,UploadedFile],
    components: { ErrorSpan },
    props: ['companies'],
    data() {
        return {
            url: 'debenture-interest-check',
            mode: "",
            editMode : true,
            fetch_module:'FETCH_DEBENTUREs',
            fiscal_year: [
                '2066/067',
                '2067/068',
                '2068/069',
                '2069/070',
                '2070/071',
                '2071/072',
                '2072/073',
                '2073/074',
                '2074/075',
                '2075/076',
                '2076/077',
                '2077/078',
                '2078/079',
                '2079/080',
                '2080/081',
                '2081/082',
                '2082/083',
                '2083/084',
                '2084/085',
                '2085/086',
                '2086/087',
                '2087/088',
                '2088/089',
                '2089/090',
                '2090/091',
                '2091/092',
                '2092/093',
                '2093/094',
                '2094/095',
                '2095/096',
                '2096/097',
                '2097/098',
                '2098/099',
            ]
        };
    },
    computed: {

    },
    mounted() {

        this.$root.$on('EDIT', (data, editMode) => {
            this.editMode = editMode;
            this.isEdit = true;
            this.item = { ...data };
            this.editMode == true ? this.mode = "Edit" : this.mode = "View";
        });

    },
    methods: {
        handleFileUpload(e) {
            const [file] = e.target.files;
            this.item.file = file;
        },
        clearSelectedFile() {
            if (this.$refs.file) {
                this.$refs.file.value = "";
            }
        }
    },
};
</script>
  
<style scoped>

</style>
  