<template>
  <flex-layout>
    <Create :parents="parents"/>
    <page-header heading="Products/Services" action="Add" toggle="offcanvas" target="#offcanvasCreate"
      @click="setCreateForm" :guard="this.$route.meta.guard" />

    <Table :tableHeading="tableHeading" />
  </flex-layout>
</template>

<script>
import FlexLayout from "../../components/Layout/FlexLayout.vue";
import Create from "./create.vue";
import Table from "./table.vue";
import PageHeader from "../../components/Utility/PageHeader.vue";
export default {
  name: "ProductService",
  components: {
    PageHeader,
    FlexLayout,
    Create,
    Table
  },
  data() {
    return {
      parents: {},
      tableHeading: [
        {
          heading: "Category",
        },
        {
          heading: "Name",
        },
        {
          heading: "Summary",
        },
        {
          heading: "Placeholder",
        },
        {
          heading: "Link",
        },
        {
          heading: "Status",
        },
      ],
    };
  },
  mounted() {
    this.fetchParents();
  },
  methods: {

    setCreateForm() {
      this.$root.$emit('CREATE');
    },

    async fetchParents() {
      let url = process.env.VUE_APP_API_URL + "product-service/getParents";
      let { data } = await this.axios.get(url);
      this.parents = data.parents;
      console.log("data",  this.parents)
    },
  }
};
</script>
