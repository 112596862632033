<template>
    <flex-layout>
        <RightShareEligibilityCheckModal :companies="companies" />
        <RightShareEligibilityCheckDataModal :companies="companies" />

        <page-header heading="Right Share Eligibility Check" action="Add" toggle="offcanvas" :guard="this.$route.meta.guard"
            target="#offcanvasCreate" @click="setCreateForm">

            <template v-slot:delete>
                <div>
                    <button class="btn btn-sm btn-outline-danger" @click="deleteAllData()"><i
                            class="ic-checklist"></i>Delete All</button>
                </div>
            </template>
            <template v-slot:example>
                <vue-excel-xlsx class="btn btn-sm btn-outline-yellow" :data="right_share_eligibility.data"
                    :columns="right_share_eligibility.columns" file-name="rightshareeligibilitytemplate"
                    :file-type="'xlsx'" sheet-name="RightShareEligibilityTemplate">
                    <i class="ic-download" /> Template
                </vue-excel-xlsx>
            </template>
            <template v-slot:export>
                <a :href="uri+'/export'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
                </a>
            </template>
        </page-header>

        <RightShareEligibilityCheckTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import RightShareEligibilityCheckTable from "@/components/RightShareEligibilityCheck/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import RightShareEligibilityCheckModal from "@/components/RightShareEligibilityCheck/modal.vue";
import RightShareEligibilityCheckDataModal from "@/components/RightShareEligibilityCheck/datamodal.vue";  
export default {
    name: "RightShareEligibilityCheck",
    components: {
        PageHeader,
        FlexLayout,
        RightShareEligibilityCheckTable,
        RightShareEligibilityCheckModal,
        RightShareEligibilityCheckDataModal
    },
    data() {
        return {
            companies: {},
            uri: process.env.VUE_APP_API_URL + 'right-share-eligibility-check',
            tableHeading: [
                {
                    heading: "Company",
                },
                {
                    heading: "BOID",
                },
                {
                    heading: "Name",
                },
                {
                    heading: "Physical Holder No",
                },
                {
                    heading: "Public Share",
                },
                {
                    heading: "Promoter Share",
                },
                {
                    heading: "Total Kitta",
                },
                {
                    heading: "Eligible for Right",
                }
            ],
            right_share_eligibility: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "name",
                        field: "name",
                    },
                    {
                        label: "physical_holder_no",
                        field: "physical_holder_no",
                    },
                    {
                        label: "public_share",
                        field: "public_share",
                    },
                    {
                        label: "promoter_share",
                        field: "promoter_share",
                    },
                    {
                        label: "total_kitta",
                        field: "total_kitta",
                    },
                    {
                        label: "eligible_for_right",
                        field: "eligible_for_right",
                    },

                ],
                data: [
                    {
                        boid: '1111111111111111',
                        physical_holder_no: '111111',
                        total_kitta: '10',
                        name: "FirstName LastName",
                        public_share: "20",
                        promoter_share: "50",
                        eligible_for_right: "data"


                    },

                ],


            },

        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL + 'right-share-eligibility-check/getCompanies';
        let response = await this.axios.get(url);
        this.companies = response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },

        deleteAllData() {

            let url = this.uri + "/delete-all";

            if (confirm("Do you really want to delete all data? Make sure you have backups")) {
                this.axios.delete(url)
                    .then((response) => {
                        this.$toast.success(response.data.message, {
                            position: "bottom-right",
                        });

                        this.$root.$emit('FETCH_RIGHT_ELIGIBILITYs');

                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error("Error Deleting All data !!", {
                            position: "bottom-right",
                        });
                    });
            }
        }
    }
};
</script>
  