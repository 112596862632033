<template>
    <flex-layout>
        <ShareAllotmentCheckModal :companies="companies" />
        <ShareAllotmentCheckDataModal :companies="companies" />
        <page-header heading="Share Allotment Check" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm">
            <template v-slot:delete>
                <div>
                    <button class="btn btn-sm btn-outline-danger" @click="deleteAllData()"><i
                            class="ic-checklist"></i>Delete All</button>
                </div>
            </template>
            <template v-slot:export>
                <a :href="uri+'/export'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
                </a>
            </template>
            <template v-slot:example>
                <vue-excel-xlsx class="btn btn-sm btn-outline-yellow" :data="share_allotment.data"
                    :columns="share_allotment.columns" file-name="shareallotmenttemplate" :file-type="'xlsx'"
                    sheet-name="ShareAllotmentTemplate">
                    <i class="ic-download" /> Template
                </vue-excel-xlsx>
            </template>
        </page-header>
        <ShareAllotmentCheckTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import ShareAllotmentCheckTable from "@/components/ShareAllotmentCheck/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import ShareAllotmentCheckModal from "@/components/ShareAllotmentCheck/modal.vue";
import ShareAllotmentCheckDataModal from "@/components/ShareAllotmentCheck/datamodal.vue";

export default {
    name: "ShareAllotmentCheck",
    components: {
        PageHeader,
        FlexLayout,
        ShareAllotmentCheckTable,
        ShareAllotmentCheckModal,
        ShareAllotmentCheckDataModal
    },
    data() {
        return {
            companies: {},
            uri: process.env.VUE_APP_API_URL + 'share-allotment-check',
            tableHeading: [
                {
                    heading: "Company",
                },
                {
                    heading: "BOID",
                },
                {
                    heading: "Share Holder No.",
                },
                {
                    heading: "Allotted Kitta",
                },
            ],
            share_allotment: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "name",
                        field: "name",
                    },
                    {
                        label: "applied_kitta",
                        field: "applied_kitta",
                    },
                    {
                        label: "allotted_kitta",
                        field: "allotted_kitta",
                    },
                    {
                        label: "tax_amt",
                        field: "tax_amt",
                    },
                    {
                        label: "app_no",
                        field: "app_no",
                    },
                    {
                        label: "int_amount",
                        field: "int_amount",
                    },
                    {
                        label: "net_refund",
                        field: "net_refund",
                    }


                ],
                data: [
                    {
                        boid: '1111111111111111',
                        name: 'FirstName LastName',
                        int_amount: '558893',
                        net_refund: '255',
                        tax_amt: '20.3',
                        app_no: '222222',
                        allotted_kitta: '10',
                        applied_kitta: '10',
                    },

                ],


            },
        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL + 'share-allotment-check/getCompanies';
        let response = await this.axios.get(url);
        this.companies = response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },

        deleteAllData() {

            let url = this.uri + "/delete-all";

            if (confirm("Do you really want to delete all data?")) {
                this.axios.delete(url)
                    .then((response) => {
                        this.$toast.success(response.data.message, {
                            position: "bottom-right",
                        });

                        this.$root.$emit('FETCH_SHAREs');

                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error("Error Deleting All data !!", {
                            position: "bottom-right",
                        });
                    });
            }
        }
    }
};
</script>
  <!--  -->