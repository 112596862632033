<template>
    <flex-layout styleClasses="p-24">
        <TableQuery :params="params" :sorts="sorts" :resetFilters="resetFilters" :sortBy="sortBy" />

        <div class="position-relative h-100">
            <absolute-layout :scrollable="true" styleClass="table-responsive">
                <table class="table table-01" width="50%">
                    <thead>
                        <th v-for="item in tableHeading" :key="item.index">
                            {{ item.heading }}
                        </th>

                        <th>Actions</th>
                    </thead>
                    <Spinner v-if="isLoading" />
                    <TableData :tableData="tableData" v-else>
                        <tr v-for="item in tableData" :key="item.index">
                            <td>
                                {{ item.title }}
                            </td>
                            <td>
                                {{ item.type | stripSlug }}
                            </td>
                            <td>
                                {{ item.link }}
                            </td>

                            <td>
                                <div class="badge rounded-pill"
                                    :class="{ 'bg-red': !item.status, 'bg-green': item.status }">
                                    {{ item.status ? "Active" : "In-Active" }}
                                </div>
                            </td>
                            <td class="action">
                                <button title="Edit" class="btn btn-sm btn-gray" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasCreate" aria-controls="offcanvasCreate"
                                    @click.prevent="setEditData(item)" v-if="$can($route.meta.guard+'.update')">
                                    <i class="ic-edit"></i>
                                </button>
                                <button title="Delete" class="btn btn-sm btn-gray" @click="deleteData(item.id)" v-if="$can($route.meta.guard+'.delete')">
                                    <i class="ic-delete"></i>
                                </button>
                            </td>
                        </tr>
                    </TableData>
                </table>
                <div class="table-footer">
                    <div class="align-center">
                        <p>View</p>
                        <select name="per_page" id="page" v-model="params.per_page" class="form-select-custom">
                            <option :value="option" v-for="(option, index) in options" :key="index"
                                :selected="option == params.per_page">
                                {{ option }}
                            </option>
                        </select>
                        <p>per page</p>
                    </div>
                    <paginate :page-class="'page-item'" :page-count="pageCount" :page-link-class="'page-link'"
                        :container-class="'pagination  pagination-sm'" :prev-text="`<i class='ic-chevron-left'></i>`"
                        :next-text="` <i class='ic-chevron-right'></i>`" :prev-class="'page-link pagination-arrow mr-2'"
                        :next-class="'page-link pagination-arrow ml-2'" :click-handler="handlePagination"
                        ref="paginate"></paginate>
                </div>
            </absolute-layout>
        </div>

    </flex-layout>
</template>
  

<script>
import AbsoluteLayout from "@/components/Layout/AbsoluteLayout.vue";
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import TableMixin from "@/mixins/table.vue";
import Spinner from "@/components/Utility/Spinner.vue";
import PaginationMixin from "@/mixins/pagination.vue";
import TableQuery from "@/components/Utility/TableQuery.vue";
import TableData from "@/components/Utility/TableData.vue";
import Permissions from "@/mixins/Permissions.vue"; 

export default {
    name: 'FooterLinkTable',
    components: {
        AbsoluteLayout, FlexLayout,
        Spinner, TableQuery, TableData
    },
    mixins: [TableMixin, PaginationMixin, Permissions],

    data() {
        return {
            url: 'footer-link',
            pageCount: 0,
            options: [10, 20, 30],
            paginationDetail: {
                current_page: "",
                from: "",
                to: "",
                total: "",
                last_page: 0,
                per_page: 0,
                links: [],
                first: '',
                last: '',
                next: '',
                prev: '',
            },
            fetch_module:'FETCH_FOOTERs'
        };
    },
    methods: {
        async fetchData() {
            let uri = this.api_url + this.url;
            this.isLoading = true
            try {
                this.axios.get(uri, { params: this.params }).then(({ data }) => {
                    this.paginationDetail = data.meta;
                    this.pageCount = Math.ceil(this.paginationDetail.total / this.paginationDetail.per_page);
                    this.tableData = data.data;
                    this.isLoading = false;
                });
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },

        async handlePagination(pageNum) {
            console.log(this.$refs.paginate)
            const newParams = { ...this.params };
            newParams.page = pageNum;
            let uri = this.api_url + this.url;
            try {
                let { data } = await this.axios.get(uri, { params: newParams });
                this.tableData = data.data;
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
    },

};
</script>
<style scoped>

</style>
    