<template>
  <flex-layout>
    <CreateMenu  :link_types="link_types" :menu_types="menu_types" :parents="parents" />
    <page-header heading="Menus" action="Add" 
                toggle="offcanvas"
                target="#offcanvasCreate"
                @click="setCreateForm" :guard="this.$route.meta.guard"
    />
    
    <menu-table :tableHeading="tableHeading"/>
  </flex-layout>
</template>

<script>
  import FlexLayout from "../../components/Layout/FlexLayout.vue";
  import CreateMenu from "./create.vue";
  // import EditMenu from "./edit.vue";
  import MenuTable from "./table.vue";
  import PageHeader from "../../components/Utility/PageHeader.vue";
  export default {
    name: "Menus",
    components: {
      // AbsoluteLayout,
      PageHeader,
      FlexLayout,
      CreateMenu,
      // EditMenu,
      MenuTable
    },
    data() {
      return {
        link_types: {},
        menu_types: {},
        parents: {},

        tableHeading: [
          {
            heading: "Parent",
          },
          {
            heading: "Name",
          },
          {
            heading: "Link",
          },
          {
            heading: "Type",
          },
          {
            heading: "Status",
          },
        ],
      };
    },
   async created() {
        let url = process.env.VUE_APP_API_URL+'menu/create';
        let response = await this.axios.get(url);
        this.link_types = response.data.links;
        this.menu_types = response.data.menu_types;
        this.parents = response.data.menus;
    },
    methods: {
     
      setCreateForm(){
        this.$root.$emit('CREATE');
      },
    }
  };
</script>
