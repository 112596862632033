<template>
  <flex-layout>
    <CreateMenu />
    <page-header heading="Download Category" action="Add" 
                toggle="offcanvas"
                target="#offcanvasCreate"
                @click="setCreateForm"
                :guard="this.$route.meta.guard"
    />
    
    <Table :tableHeading="tableHeading"/>
  </flex-layout>
</template>

<script>
  import FlexLayout from "../../components/Layout/FlexLayout.vue";
  import CreateMenu from "./create.vue";
  import Table from "./table.vue";
  import PageHeader from "../../components/Utility/PageHeader.vue";
  export default {
    name: "DownloadCategory",
    components: {
      PageHeader,
      FlexLayout,
      CreateMenu,
      Table
    },
    data() {
      return {
        link_types: {},
        menu_types: {},
        parents: {},

        tableHeading: [
          {
            heading: "Category Name",
          },
           {
            heading: "Slug",
          },
          {
            heading: "Status",
          },
        ],
      };
    },
   created() {
        
    },
    methods: {
     setCreateForm(){
        this.$root.$emit('CREATE');
      },
    }
  };
</script>
