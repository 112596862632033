<template>
    <flex-layout>
        <MFSchemeModal />
        <page-header heading="Mutual Fund Scheme" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />

        <MFSchemeTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import MFSchemeTable from "@/components/MutualFund/Scheme/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import MFSchemeModal from "@/components/MutualFund/Scheme/modal.vue";
export default {
    name: "MutualFundScheme",
    components: {
        PageHeader,
        FlexLayout,
        MFSchemeTable,
        MFSchemeModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Title",
                },

                {
                    heading: "Status",
                },
            ],
        };
    },

    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        }
    }
};
</script>
  