<template>
    <flex-layout>
        <InvestorRelationModal :categories="categories"/>
        <page-header heading="Investor Relation" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />

        <InvestorRelationTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import InvestorRelationTable from "@/components/InvestorRelation/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import InvestorRelationModal from "@/components/InvestorRelation/modal.vue";
export default {
    name: "InvestorRelation",
    components: {
        PageHeader,
        FlexLayout,
        InvestorRelationTable,
        InvestorRelationModal
    },
    data() {
        return {
            categories:{},
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "Category",
                },
                {
                    heading: "File",
                },
                {
                    heading: "Status",
                }
            ],
        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL+'investor-relation-category/categories';
        let response = await this.axios.get(url);
        this.categories =response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  