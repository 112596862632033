<template>
    <flex-layout>
        <DepartmentTypeModal/>
        <page-header heading="Department Type" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm" />

        <DepartmentTypeTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import DepartmentTypeTable from "@/components/DepartmentType/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import DepartmentTypeModal from "@/components/DepartmentType/modal.vue";

export default {
    name: "Menus",
    components: {
        // AbsoluteLayout,
        PageHeader,
        FlexLayout,
        DepartmentTypeTable,
        DepartmentTypeModal,
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Name",
                },
                {
                    heading: "Date",
                },
                {
                    heading: "Thumbnail",
                },
                {
                    heading: "Active Image Count",
                },
                {
                    heading: "Status",
                },
                {
                    heading: "Order",
                },
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    },

};
</script>
  