<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Images</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
                    <div class="col-md-12 mb-16">
                        <label for="">Name</label>
                        <input type="text" class="form-control" v-model="item.name" />
                        <ErrorSpan :error="errors.name" />
                    </div>
                    <div class="col-md-12 mb-16">
                        <label for="">Album</label>
                        <select name="album_id" class="form-select" :class="{ 'border-red': errors.album_id }"
                            v-model="item.album_id">
                            <option value="" disabled selected>Select Album</option>
                            <option v-for="(cat, key) in albums" :key="key" :value="cat.id">{{ cat.name }}</option>
                        </select>
                        <ErrorSpan :error="errors.album_id" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Description</label>
                        <ckeditor v-model="item.description"></ckeditor>
                        <ErrorSpan :error="errors.description" />

                    </div>
                    <!-- <div class="row col-md-12 mb-16">
                        <div class="col-md-6">
                            <label for="">Image</label>
                            <input type="file" class="form-control" ref="file" @input="handleFileUpload" />
                            <ErrorSpan :error="errors.image" />
                            <div class="col-md-6 mt-16" v-if="upload.status == false">
                                <a v-if="item.image" :href="storage + item.image" target="_blank">
                                    <img :src="storage + item.image" alt="" height="50" width="50">
                                </a>
                            </div>
                            <div class="col-md-6 mt-16" v-else>
                                <img :src="upload.imgPreview" alt="" height="100" width="100">
                            </div>
                        </div>
                    </div> -->

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="textbox-04">
                                <label for="">Image</label>
                            </div>
                        </div>
                        <div class="col-md-12 mb-6">
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="form-group">
                                        <div class="row" v-if="filePreview.length > 0">
                                            <div class="position-relative m-4">
                                                <button @click="clearAll"
                                                    class="btn btn-icon btn-danger cropper-close-btn flex-between col-md-3">
                                                    Clear All <i class="ic-close"></i>
                                                </button>
                                                <p class="text-danger">
                                                    * {{ filePreview.length }} Image Selected
                                                </p>
                                            </div>
                                        </div>
                                        <div v-else role="button">
                                            <input class="form-control" type="file" ref="uploadFile"
                                                @input="handleFileUpload" multiple="multiple" accept="image/*" />
                                        </div>
                                        <ErrorSpan :error="errors.image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="filePreview.length > 0">
                        <div class="card-02 pb-0 mt-3" v-for=" (item, index) in filePreview" :key="index">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="textbox-04">
                                        <span>
                                            <i class="ic-close" @click.prevent="clearImage(index)"></i>
                                        </span>
                                        <img :src="item" alt="" style="height: 100%; width: 100%;"
                                            class="resize mx-md-6 mb-16 mt-12">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="col-md-6 mb-16">
                        <label for="">Display Order</label>
                        <input type="number" min="1" @input="item.order_by = item.order_by < 1 ? '' : item.order_by"
                            class="form-control" :class="{ 'border-red': errors.order_by }" v-model="item.order_by" />
                        <ErrorSpan :error="errors.order_by" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1"
                                v-model="item.status" />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
                        </div>
                    </div>

                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <button v-if="!isLoading" class="btn btn-green" type="submit">
                            <i class="ic-checkmark mr-8"></i>Save
                        </button>
                        <button v-else class="btn btn-green" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";
import CKEditor from 'ckeditor4-vue';
import UploadPreviewMixin from "@/mixins/uploadpreview.vue";


export default {
    name: "GalleryModal",
    mixins: [CreateMixin, UploadPreviewMixin],
    components: { ErrorSpan, ckeditor: CKEditor.component },
    props: ['albums'],
    data() {
        return {
            url: 'gallery',
            mode: "",
            fetch_module: 'FETCH_GALLERYs',
            item_struct: {
                id: "",
                name: "",
                description: "",
                image: "",
                order_by: 1,
                status: true,
                album_id: "",
            },
            image: [],
            filePreview: [],
        };
    },
    computed: {
    },
    mounted() {
        this.$root.$on('CREATE', () => {
            this.isEdit = false;
            this.item = {
            };
            this.errors = {};
            this.mode = "Add";
            this.clearSelectedFiles();
            this.item.image = [];
            this.filePreview = [];
        });

    },
    methods: {
        clearSelectedFiles() {
            if (this.$refs.file) {
                this.$refs.file.value = null
                this.upload.status = false
            }
        },
        openFileDialog() {
            this.$refs.uploadFile.click();
        },
        handleFileUpload(e) {
            this.image = [];
            this.filePreview = [];
            const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg", "webp"];

            for (let i = 0; i < e.target.files.length; i++) {
                if (imageExtensions.includes(e.target.files[i].type.split("/")[1])) {
                    this.image.push(e.target.files[i]);
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.filePreview.push(e.target.result);
                    };
                    reader.readAsDataURL(e.target.files[i]);
                }
            }
        },
        clearAll() {
            this.filePreview = [];
            this.image = [];

        },
        clearImage(index) {
            this.filePreview.splice(index, 1);
            this.image.splice(index, 1);
        },
        submitForm() {

            let uri = this.api_url + this.url + '/store';
            this.isLoading = true;

            let payload = new FormData();
            payload.append('name', this.item.name);
            payload.append('description', this.item.description);
            payload.append('album_id', this.item.album_id);
            payload.append('order_by', this.item.order_by);
            payload.append('status', this.item.status);

            if (this.image.length > 0) {
                this.image.forEach(element => {
                    payload.append('image[]', element);
                });
            }


            if (this.id != '') {
                payload.id = this.id;
            }

            this.axios.post(uri, payload
            ).then((response) => {
                this.isLoading = false;
                this.$toast.success(response.data.message, {
                    position: "bottom-right",
                });
                this.$refs.btnClose.click();
                this.$root.$emit('FETCH_GALLERYs');
            }).catch((error) => {
                this.isLoading = false;
                if (error.response) {
                    this.pushErrors(error.response.data.errors);
                    this.$toast.error("Please check the form again.", {
                        position: "bottom-right",
                    });
                }
            });
        },

    },
};
</script>
  
<style scoped></style>
  