<template>
  <flex-layout>
    <Create />
    <page-header heading="Certificate Lost Notice" action="Add" toggle="offcanvas" target="#offcanvasCreate"
      @click="setCreateForm" :guard="this.$route.meta.guard">
      <template v-slot:export>
        <div>
          <button class="btn btn-sm btn-outline-danger" @click="showModal"><i class="ic-checklist"></i>Import</button>

          <!-- Modal -->
          <div v-if="isModalVisible" class="modal fade show" tabindex="-1" style="display: block;">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Import Excel File</h5>
                  <button type="button" class="btn-close" @click="hideModal"></button>
                </div>
                <div class="modal-body">
                  <input type="file" @change="validateFile" accept=".xlsx,.xls" ref="excelImport" class="form-control" />
                  <span v-if="fileError" class="text-danger mt-2">{{ fileError }}</span>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="hideModal">Close</button>
                  <button type="button" class="btn btn-primary" @click="importFile"
                    >Import</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </page-header>

    <Table :tableHeading="tableHeading" />
  </flex-layout>
</template>

<script>
import FlexLayout from "../../components/Layout/FlexLayout.vue";
import PageHeader from "../../components/Utility/PageHeader.vue";
import Create from "./create.vue";
import Table from "./table.vue";
export default {
  name: "CertificateLostNotice",
  components: {
    PageHeader,
    FlexLayout,
    Create,
    Table
  },
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      isModalVisible: false,
      fileError: '',
      excelImport: null,
      tableHeading: [
        {
          heading: "Company Name",
        },
        {
          heading: "publish Date",
        },
        {
          heading: "End Date",
        },
        {
          heading: "File",
        },

      ],
    };
  },
  created() {

  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    hideModal() {
      this.isModalVisible = false;
    },
    validateFile(event) {
      const file = event.target.files[0];
      const validExtensions = ['xlsx', 'xls'];
      const fileExtension = file.name.split('.').pop();
      if (!validExtensions.includes(fileExtension)) {
        this.fileError = 'Invalid file type. Please upload an Excel file.';
      } else {
        this.fileError = '';
      }
    },
    importFile() {
      const formData = new FormData();
      formData.append('file', this.$refs.excelImport.files[0]);

      let uri = this.api_url + 'certificate-lost-notice/import-excel';
      this.axios.post(uri, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
            this.$toast.success(response.data.message, {
              position: "bottom-right",
            });
            location.reload();
      })
      .catch(error => {
        // console.error('Error importing file:', error.response.data.message);
        this.$toast.error(error.response.data.message,{
                position: "bottom-right",
              });
      });

      this.hideModal();
    },
    setCreateForm() {
      this.$root.$emit('CREATE');
    },
  }
};
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  max-width: 800px;
}

.modal-content {
  padding: 20px;
}
</style>