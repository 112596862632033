<template>
    <flex-layout>
        <LoginLinkModal/>
        <page-header heading="Login Links" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />

        <LoginLinkTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import LoginLinkTable from "@/components/LoginLink/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import LoginLinkModal from "@/components/LoginLink/modal.vue";
export default {
    name: "LoginLink",
    components: {
        PageHeader,
        FlexLayout,
        LoginLinkTable,
        LoginLinkModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Name",
                },
                {
                    heading: "Slug",
                },
                {
                    heading: "Link",
                },
                {
                    heading: "Order",
                },
                {
                    heading: "Status",
                },
                
            ],
        };
    },
   
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  