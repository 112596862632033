<template>
    <flex-layout>
        <page-header heading="Latest Market Price" />
        <div class="col-md-12 p-48 ">
            <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">

                <div class="col-md-5 mb-16">
                    <label for="">Latest Price</label>
                    <input type="text" class="form-control" v-model="item.latest_market_price" />
                    <ErrorSpan :error="errors.latest_market_price" />
                </div>

                <div class="col-md-5 mb-16">
                    <label for="">Choose Fluctuation Type</label>
                    <select name="change_type" class="form-select" :class="{ 'border-red': errors.change_type }"
                        v-model="item.change_type">
                        <option value="" disabled selected>Select Type</option>
                        <option v-for="(cat, key) in options" :key="key" :value="cat.id">{{ cat.type }}</option>
                    </select>
                    <ErrorSpan :error="errors.change_type" />
                </div>

                <div class="col-md-3 mb-16">
                    <label for="">Date</label>
                    <input type="text" class="form-control" v-model="item.date" />
                    <ErrorSpan :error="errors.date" />
                </div>

                <div class="col-md-3 mb-16">
                    <label for="">Price Type</label>
                    <select name="price_type" class="form-select" :class="{ 'border-red': errors.change_type }"
                        v-model="item.price_type">
                        <option value="" disabled selected>Select Type</option>
                        <option v-for="(item,index) in price_types" :key="index"  :value="item.type">{{ item.type }}</option>
                    </select>
                    <ErrorSpan :error="errors.price_type" />
                </div>

                <div  class="col-md-3 mb-16">
                    <label for="">Price Percent</label>
                    <input type="text" class="form-control" v-model="item.price_percent" />
                    <ErrorSpan :error="errors.price_percent" />

                </div>

                <!-- <div class="col-md-5 mb-16">
                    <label for="">Fluctuation %</label>
                    <input type="text" class="form-control" v-model="item.change_value" />
                    <ErrorSpan :error="errors.change_value" />
                </div>
                
                <div class="col-md-5 mb-16">
                    <label for="">Fluctuation Value</label>
                    <input type="text" class="form-control" v-model="item.change_percentage" />
                    <ErrorSpan :error="errors.change_percentage" />
                </div> -->

                <hr>
                <div class="col-md-12">
                    <button v-if="!isLoading" class="btn btn-green float-end" type="submit" @click="submitForm">
                        <i class="ic-checkmark mr-8"></i>Save
                    </button>
                    <button v-else class="btn btn-green float-end" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </form>
        </div>



    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import ErrorSpan from "@/components/errorspan.vue";

export default {
    name: "LatestMarketPrice",
    components: {
        PageHeader,
        FlexLayout,
        ErrorSpan

    },
    data() {
        return {
            url: process.env.VUE_APP_API_URL + "latest-market-price",
            isEdit: false,
            item: {
                id: "",
                latest_market_price: "",
                date: "",
                change_type: "",
                change_percentage: ""
            },

            errors: {},
            isLoading: false,
            options: [
                { id: "1", type: "Increment" },
                { id: "2", type: "Decrement" },
            ],
            price_types: [
                {  type: "Discounted" },
                {  type: "Premium" },
                { type: "Par"}
            ],
        };

    },
    mounted() {
        this.fetchLatestMarketPrice();
    },
    methods: {

        fetchLatestMarketPrice() {

            this.axios.get(this.url).then(({ data }) => {

                let struct = { ...this.item };

                if (data.data.length > 0) {

                    this.isEdit = true;
                    this.item = data.data[0];
                }
                else {
                    this.item = struct;
                }
                this.isLoading = false;
            });

        },


        submitForm() {
            this.errors = {};
            this.isLoading = true;
            if (!this.isEdit) {
                delete this.item.id;
            }

            let uri = this.url + (this.isEdit ? '/update' : '/store');

            this.axios.post(uri, this.item).then((response) => {
                this.isLoading = false;
                this.$toast.success(response.data.message, {
                    position: "bottom-right",
                });
                this.fetchLatestMarketPrice();
            }).catch((error) => {
                this.isLoading = false;
                if (error.response) {
                    if (error.response.status == 422) {
                        this.pushErrors(error.response.data.errors);
                        this.$toast.error("Please check the form again.", {
                            position: "bottom-right",
                        });
                    }
                }
            });
        },
        pushErrors(errors) {
            Object.entries(errors).forEach((element) => {
                let [key, msg] = element;
                this.errors[key] = msg[0];
            });
        }

    },

};
</script>
  