<template>
  <div
    class="offcanvas offcanvas-end offcanvas-01"
    id="offcanvasCreate"
    tabindex="-1"
    aria-labelledby="offcanvasCreateLabel"
  >
    <div class="offcanvas-header">
      <h5 class="text-dark" id="offcanvasCreateLabel">
        {{ mode }} Product/Service Category
      </h5>
      <button
        class="btn btn-danger btn-sm"
        type="button"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        ref="btnClose"
      >
        <i class="ic-close"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <form
          class="row align-end"
          v-on:submit.prevent="submitForm"
          enctype="multipart/form-data"
        >
          <div class="col-md-6 mb-16">
            <label for="">Category Name</label>
            <input
              type="text"
              class="form-control"
              :class="{ 'border-red': errors.category_name }"
              v-model="item.category_name"
            />
            <span
              v-if="errors.category_name"
              class="text-danger mt-2 font-size-13"
              >{{ errors.category_name }}</span
            >
          </div>


          <div class="col-md-6 mb-16">
            <label for="">Slug</label>
            <input
              type="text"
              class="form-control"
              :class="{ 'border-red': errors.slug }"
              v-model="item.slug"
            />
            <span v-if="errors.slug" class="text-danger mt-2 font-size-13">{{
              errors.slug
            }}</span>
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Description</label>
            <ckeditor v-model="item.description"></ckeditor>
            <span
              v-if="errors.description"
              class="text-danger mt-2 font-size-13"
              >{{ errors.description }}</span
            >
          </div>

          <div class="col-md-12">
            <button
              @click="closeForm"
              type="button"
              aria-label="Close"
              class="btn btn-red mr-16"
            >
              <i class="ic-close mr-8"></i>Cancel
            </button>
            <button v-if="!isLoading" class="btn btn-green" type="submit">
              <i class="ic-checkmark mr-8"></i>Save
            </button>
            <button v-else class="btn btn-green" type="button" disabled>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CreateMixin from "../../mixins/create.vue";
import CKEditor from "ckeditor4-vue";

export default {
  name: "CreateMenu",
  components: { ckeditor: CKEditor.component },
  mixins: [CreateMixin],
  data() {
    return {
      url: "product-service-category",
      mode: "",
      fetch_module: "FETCH_PRODUCT_CATs",
    };
  },
  props: ["parents"],
  created() {
    this.$root.$on("CREATE", () => {
      this.isEdit = false;
      this.item = {
        is_a_service: 0,
      };
      this.errors = {};
      this.mode = "Add";
    });

    this.$root.$on("EDIT", (data) => {
      this.errors = {};
      this.isEdit = true;
      this.item = { ...data };
      this.mode = "Edit";
    });
  },

  methods: {
    closeForm() {
      this.$refs.btnClose.click();
    },
  },
};
</script>

<style>
.border-red {
  border: 1px solid red;
}

.font-size-13 {
  font-size: 13px;
}
</style>
