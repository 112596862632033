<template>
    <flex-layout styleClasses="p-24">
        <TableQuery :params="params" :delete_data="delete_data" :sorts="sorts" :resetFilters="resetFilters" :sortBy="sortBy"
            :cleardeleteDataParams="cleardeleteDataParams" :deleteSpecificData="deleteSpecificData"
            slug="debenture-interest-check" />
        
        <div class="position-relative h-100">
            <absolute-layout :scrollable="true" styleClass="table-responsive">
                <table class="table table-01" width="50%">
                    <thead>
                        <th v-for="item in tableHeading" :key="item.index">
                            {{ item.heading }}
                        </th>

                        <th>Actions</th>
                    </thead>
                    <Spinner v-if="isLoading" />
                    <TableData :tableData="tableData" v-else>
                        <tr v-for="item in tableData" :key="item.index">
                            <td>
                                {{ item.company }}
                            </td>
                            <td>
                                {{ item.boid }}
                            </td>
                            <td>
                                {{ item.debenture_holder_name }}
                            </td>
                            <td>
                                {{ item.interest_amt }}
                            </td>
                            <td>
                                {{ item.interest_tax }}
                            </td>
                            <td>
                                {{ item.net_interest_amt }}
                            </td>
                            <td>
                                {{ item.semi_quarter }}
                            </td>

                            <td>
                                {{ item.fiscal_year }}
                            </td>
                            <td>
                                {{ item.remarks }}
                            </td>
                            <td>
                                <a :href="storage + item.file" target="_blank" v-if="item.file">
                                    <i class="ic-import"></i> File
                                </a>
                            </td>

                            <td class="action">
                                <button title="Edit" class="btn btn-sm btn-gray" data-bs-toggle="offcanvas"
                                    data-bs-target="#cashdatamodal" aria-controls="offcanvasCreate"
                                    @click.prevent="setEditData(item)">
                                    <i class="ic-edit"></i>
                                </button>
                                <button title="View" class="btn btn-sm btn-gray" data-bs-toggle="offcanvas"
                                    data-bs-target="#cashdatamodal" aria-controls="offcanvasCreate"
                                    @click.prevent="setEditData(item, false)" v-if="$can($route.meta.guard+'.update')">
                                    <i class="ic-view"></i>
                                </button>
                                <button title="Delete" class="btn btn-sm btn-gray" @click="deleteData(item.id)" v-if="$can($route.meta.guard+'.delete')">
                                    <i class="ic-delete"></i>
                                </button>
                            </td>
                        </tr>
                    </TableData>
                </table>
                <div class="table-footer">
                    <PerPage :options="options" :params="params" />
                    <paginate :page-class="'page-item'" :page-count="pageCount" :page-link-class="'page-link'"
                        :container-class="'pagination  pagination-sm'" :prev-text="`<i class='ic-chevron-left'></i>`"
                        :next-text="` <i class='ic-chevron-right'></i>`" :prev-class="'page-link pagination-arrow mr-2'"
                        :next-class="'page-link pagination-arrow ml-2'" :click-handler="handlePagination"
                        ref="paginate"></paginate>
                </div>
            </absolute-layout>
        </div>

    </flex-layout>
</template>
  

<script>
import AbsoluteLayout from "@/components/Layout/AbsoluteLayout.vue";
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import TableMixin from "@/mixins/table.vue";
import Spinner from "@/components/Utility/Spinner.vue";
import PaginationMixin from "@/mixins/pagination.vue";
import PerPage from "@/components/Utility/PerPage.vue";
import TableQuery from "@/components/Utility/CheckpointsTableQuery.vue";
import TableData from "@/components/Utility/TableData.vue";
import Permissions from "@/mixins/Permissions.vue";

export default {
    name: 'DebentureInterestCheckTable',
    components: {
        AbsoluteLayout, FlexLayout, PerPage,
        Spinner, TableQuery, TableData
    },
    mixins: [TableMixin, PaginationMixin, Permissions],

    data() {
        return {
            url: 'debenture-interest-check',
            fetch_module:'FETCH_DEBENTUREs',
        };
    },
    methods: {
    },

};
</script>
<style scoped>

</style>
    