<template>
    <flex-layout styleClasses="p-24">
        <TableQuery :params="params" :delete_data="delete_data" :sorts="sorts" :resetFilters="resetFilters" :sortBy="sortBy"
            :cleardeleteDataParams="cleardeleteDataParams" :deleteSpecificData="deleteSpecificData"
            slug="wacc-check" />

        <div class="position-relative h-100">
            <absolute-layout :scrollable="true" styleClass="table-responsive">
                <table class="table table-01">
                    <thead>
                        <th v-for="item in tableHeading" :key="item.index">
                            {{ item.heading }}
                        </th>
                        <th>Actions</th>
                    </thead>
                    <Spinner v-if="isLoading" />
                    <Tabledata :tableData="tableData" v-else>
                        <tr v-for="item in tableData" :key="item.index">
                            <td>
                                {{ item.company }}
                            </td>
                            <td>
                                {{ item.boid }}
                            </td>
                            <td>
                                {{ item.name }}
                            </td>
                            <td>
                                {{ item.short_term_units }}
                            </td>
                            <td>
                                {{ item.long_term_units }}
                            </td>
                            <td>
                                {{ item.total_units }}
                            </td>
                            <td>
                                {{ item.gross_refund }}
                            </td>
                            <td>
                                {{ item.total_tax }}
                            </td>
                            <td>
                                {{ item.net_refund }}
                            </td>
                            <td>
                                {{ item.wacc }}
                            </td>
                            <td class="action">
                                <button title="Edit" class="btn btn-sm btn-gray" data-bs-toggle="offcanvas"
                                    data-bs-target="#waccdatamodal" aria-controls="offcanvasCreate"
                                    @click.prevent="setEditData(item)" v-if="$can($route.meta.guard + '.update')">
                                    <i class="ic-edit"></i>
                                </button>
                                <button title="View" class="btn btn-sm btn-gray" data-bs-toggle="offcanvas"
                                    data-bs-target="#waccdatamodal" aria-controls="offcanvasCreate"
                                    @click.prevent="setEditData(item, false)">
                                    <i class="ic-view"></i>
                                </button>
                                <button title="Delete" class="btn btn-sm btn-gray" @click="deleteData(item.id)"
                                    v-if="$can($route.meta.guard + '.delete')">
                                    <i class="ic-delete"></i>
                                </button>
                            </td>
                        </tr>
                    </Tabledata>
                </table>

                <div class="table-footer">
                    <PerPage :options="options" :params="params" />
                    <paginate :page-class="'page-item'" :page-count="pageCount" :page-link-class="'page-link'"
                        :container-class="'pagination  pagination-sm'" :prev-text="`<i class='ic-chevron-left'></i>`"
                        :next-text="` <i class='ic-chevron-right'></i>`" :prev-class="'page-link pagination-arrow mr-2'"
                        :next-class="'page-link pagination-arrow ml-2'" :click-handler="handlePagination" ref="paginate">
                    </paginate>
                </div>

            </absolute-layout>
        </div>

    </flex-layout>
</template>
  
<script>
import AbsoluteLayout from "@/components/Layout/AbsoluteLayout.vue";
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import TableMixin from "@/mixins/table.vue";
import PaginationMixin from "@/mixins/pagination.vue";
import Spinner from "@/components/Utility/Spinner.vue";
import PerPage from "@/components/Utility/PerPage.vue";
import TableQuery from "@/components/Utility/CheckpointsTableQuery.vue";
import Tabledata from '@/components/Utility/TableData.vue';
import Permissions from "@/mixins/Permissions.vue";

export default {
    name: 'WaccCheckTable',
    components: {
        'absolute-layout': AbsoluteLayout, FlexLayout,
        Spinner,
        PerPage,
        TableQuery,
        Tabledata
    },
    data() {
        return {
            url: 'wacc-check',
            fetch_module: 'FETCH_WACCs',
        };
    },
    mixins: [TableMixin, PaginationMixin, Permissions],
    methods: {
    },

};
</script>
<style scoped></style>
    