<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} FAQ</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
                    <div class="col-md-12 mb-16">
                        <label for="">Title</label>
                        <!-- <input type="text" class="form-control" v-model="item.title" /> -->
                        <textarea class="form-control" v-model="item.title" rows="2" />
                        <ErrorSpan :error="errors.title" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Description</label>
                        <ckeditor v-model="item.description"></ckeditor>
                        <span v-if="errors.description" class="text-danger mt-2 font-size-13">{{ errors.description
                        }}</span>
                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">Faq Category</label>
                        <select name="category_id" class="form-select" :class="{ 'border-red': errors.category_id }"
                            v-model="item.category_id">
                            <option value="" disabled selected>Select Category</option>
                            <option v-for="(cat, key) in categories" :key="key" :value="cat.id">{{ cat.name }}</option>
                        </select>
                        <ErrorSpan :error="errors.category_id" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">Display Order</label>
                        <input type="number" min="0"
                            @input="item.order_by = item.order_by < 0 ? '' : item.order_by"
                            class="form-control" :class="{ 'border-red': errors.order_by }"
                            v-model="item.order_by" />
                        <span v-if="errors.order_by" class="text-danger font-size-13">{{ errors.order_by }}</span>
                    </div>

                    <div class="col-md-6 mb-16">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1"
                                v-model="item.status" />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
                        </div>
                    </div>
                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <button v-if="!isLoading" class="btn btn-green" type="submit">
                            <i class="ic-checkmark mr-8"></i>Save
                        </button>
                        <button v-else class="btn btn-green" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
import CreateMixin from "@/mixins/create.vue"
import UploadPreviewMixin from "@/mixins/uploadpreview.vue";
import ErrorSpan from "@/components/errorspan.vue";
import CKEditor from 'ckeditor4-vue';

export default {
    name: "FAQModal",
    mixins: [CreateMixin, UploadPreviewMixin],
    components: { ErrorSpan, ckeditor: CKEditor.component },
    data() {
        return {
            url: 'faqs',
            fetch_module: 'FETCH_FAQs',
            categories: {}
        };
    },
    computed: {

    },
    mounted() {
        this.$root.$on('CREATE', () => {
            this.isEdit = false;
            this.item = {
                status: 0,
            };
            this.errors = {};
            this.mode = "Add";

        });

        this.$root.$on('EDIT', (data) => {
            this.errors = {};
            this.isEdit = true;
            this.item = { ...data };
            this.mode = "Edit";
        });

        this.fetchCategories();
    },
    methods: {

        async fetchCategories() {
            let url = process.env.VUE_APP_API_URL + 'faqs/categories';
            let response = await this.axios.get(url);
            this.categories = response.data;
        }
    },
};
</script>
  
<style scoped></style>
  