<template>
  <flex-layout>
    <CreateMenu  :link_types="link_types" :menu_types="menu_types" :parents="parents"/>
    <page-header heading="News & Notices" action="Add" 
                toggle="offcanvas"
                target="#offcanvasCreate"
                :guard="this.$route.meta.guard"
                @click="setCreateForm"
    />
    
    <menu-table :tableHeading="tableHeading"/>
  </flex-layout>
</template>

<script>
  import FlexLayout from "../../components/Layout/FlexLayout.vue";
  import CreateMenu from "./create.vue";
  // import EditMenu from "./edit.vue";
  import MenuTable from "./table.vue";
  import PageHeader from "../../components/Utility/PageHeader.vue";
  export default {
    name: "Menus",
    components: {
      // AbsoluteLayout,
      PageHeader,
      FlexLayout,
      CreateMenu,
      // EditMenu,
      MenuTable
    },
    data() {
      return {
        link_types: {},
        menu_types: {},
        parents: {},

        tableHeading: [
          {
            heading: "Title",
          },
          {
            heading: "Slug",
          },
          {
            heading: "Feature Image",
          },
          {
            heading: "File",
          },
          {
            heading: "News/Notice",
          },
          {
            heading: "Status",
          },
        ],
      };
    },
   created() {
        
    },
    mounted() {
      const {name,age}=this.$store.state.auth.user;
      console.log(name,age);
    },
    methods: {
     
      setCreateForm(){
        this.$root.$emit('CREATE');
      },
    }
  };
</script>
