<template>
    <flex-layout>
        <ProductShowCaseModal :products="products" />
        <page-header heading="Product ShowCase" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm" />
        <ProductShowCaseTable :tableHeading="tableHeading" />
    </flex-layout>
</template>

<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import ProductShowCaseTable from "@/components/ProductShowCase/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import ProductShowCaseModal from "@/components/ProductShowCase/modal.vue";
export default {
    name: "ProductShowCase",
    components: {
        PageHeader,
        FlexLayout,
        ProductShowCaseTable,
        ProductShowCaseModal
    },
    data() {
        return {
            products: [],
            tableHeading: [
            {
                    heading: "Product",
                },
                {
                    heading: "Title",
                },
                {
                    heading: "Subtitle",
                },
                {
                    heading: "Description",
                },
                {
                    heading: "Status",
                },
            ],
        };
    },
    methods: {

        async fetchProducts() {
            let uri = process.env.VUE_APP_API_URL + 'product-showcase/getProducts'
            this.isLoading = true
            try {
                this.axios.get(uri).then(({ data }) => {
                    this.products = data.products;
                });
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },

        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    },

    mounted() {
        this.fetchProducts();
    },
};
</script>
