<template>
    <flex-layout>
        <HelpfulToolModal/>
        <page-header heading="Helpful Tools" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />
        <HelpfulToolTable  :tableHeading="tableHeading"/>
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import HelpfulToolTable from "@/components/HelpfulTool/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import HelpfulToolModal from "@/components/HelpfulTool/modal.vue";
export default {
    name: "HelpfulTools",
    components: {
        PageHeader,
        FlexLayout,
        HelpfulToolTable,
        HelpfulToolModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "Link",
                },
                {
                    heading: "Status",
                },
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  