<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="RightShareAllotmentDataModal" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Right Share Allotment Data</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">

                    <div class="col-md-12 mb-16">
                        <label for="">BOID</label>
                        <input type="text" class="form-control" v-model="item.boid" :readonly="!editMode" :disabled="!editMode"/>
                        <ErrorSpan :error="errors.boid" />
                    </div>
                    <div class="col-md-12 mb-16">
                        <label for="">Share Holder No.</label>
                        <input type="text" class="form-control" v-model="item.share_holder_no" :readonly="!editMode" :disabled="!editMode"/>
                        <ErrorSpan :error="errors.share_holder_no" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Allotted Kitta</label>
                        <input type="text" class="form-control" v-model="item.allotted_kitta" :readonly="!editMode" :disabled="!editMode"/>
                        <ErrorSpan :error="errors.allotted_kitta" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Company Name</label>
                        <select name="company_id" class="form-select" :class="{'border-red':errors.company_id}"
                            v-model="item.company_id" :readonly="!editMode" :disabled="!editMode">
                            <option value="" disabled selected>Select Company</option>
                            <option v-for="(cat,key) in companies" :key="key" :value="cat.id">{{cat.name}}</option>
                        </select>
                        <ErrorSpan :error="errors.company_id" />
                    </div>
                 
                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <template v-if="editMode">
                            <button v-if="!isLoading" class="btn btn-green" type="submit">
                                <i class="ic-checkmark mr-8"></i>Save
                            </button>
                            <button v-else class="btn btn-green" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        </template>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";

export default {
    name: "RightShareAllotmentDataModal",
    mixins: [CreateMixin],
    components: { ErrorSpan },
    props: ['companies'],
    data() {
        return {
            url: 'right-share-allotment',
            mode: "",
            editMode: true,
            fetch_module:'FETCH_RIGHT_ALLOTMENTs',
        };
    },
    computed: {

    },
    mounted() {

        this.$root.$on('EDIT', (data, editMode) => {
            this.editMode = editMode;
            this.isEdit = true;
            this.item = { ...data };
            this.editMode == true ? this.mode = "Edit" : this.mode = "View";

        });

    },
    methods: {
    },
};
</script>
  
<style scoped>

</style>
  