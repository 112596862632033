<template>
    <flex-layout>
        <WaccCheckModal :companies="companies" />


        <WaccCheckDataModal :companies="companies" />

        <pageheader heading="WACC Calculation Check - Global IME Samunat Yojana-I" action="Add" toggle="offcanvas"
            target="#offcanvasCreate" :guard="this.$route.meta.guard" @click="setCreateForm">
            <template v-slot:delete>
                <div>
                    <button class="btn btn-sm btn-outline-danger" @click="deleteAllData()"><i
                            class="ic-checklist"></i>Delete All</button>
                </div>
            </template>
            <template v-slot:example>
                <vue-excel-xlsx class="btn btn-sm btn-outline-yellow" :data="tax_template.data"
                    :columns="tax_template.columns" file-name="Waccchecktemplate" :file-type="'xlsx'"
                    sheet-name="WaccCheckTemplate">
                    <i class="ic-download" /> Template
                </vue-excel-xlsx>
            </template>
            <template v-slot:export>
                <a :href="uri + '/export'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
                </a>
            </template>


        </pageheader>

        <WaccCheckTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import WaccCheckTable from "@/components/WaccCheck/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import WaccCheckModal from "@/components/WaccCheck/modal.vue";
import WaccCheckDataModal from "@/components/WaccCheck/datamodal.vue";

export default {
    name: "WaccCheck",
    components: {
        pageheader: PageHeader,
        FlexLayout,
        WaccCheckTable,
        WaccCheckModal,
        WaccCheckDataModal,
    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'wacc-check',
            companies: {},
            tableHeading: [
                {
                    heading: "Company",
                },
                {
                    heading: "BOID",
                },
                {
                    heading: "Name",
                },
                {
                    heading: "Short Term Units (After 2079.4.20)",
                },
                {
                    heading: "Long  Term Units (Bef. 2079.4.20)",
                },
                {
                    heading: "Calculated Total Units",
                },
                {
                    heading: "Gross Refund",
                },
                {
                    heading: "Total Tax",
                },
                {
                    heading: "Net Refund",
                },
                {
                    heading: "Wacc",
                },
            ],

            tax_template: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "name",
                        field: "name",
                    },
                    {
                        label: "short_term_units",
                        field: "short_term_units",
                    },
                    {
                        label: "long_term_units",
                        field: "long_term_units",
                    },
                    {
                        label: "total_units",
                        field: "total_units",
                    },
                    {
                        label: "gross_refund",
                        field: "gross_refund",
                    },
                    {
                        label: "total_tax",
                        field: "total_tax",
                    },
                    {
                        label: "net_refund",
                        field: "net_refund",
                    },
                    {
                        label: "wacc",
                        field: "wacc",
                    }
                ],
                data: [
                    {
                        boid: '1111111111111111',
                        name: 'Firstname Surname LastName',
                        short_term_units: '4444',
                        long_term_units: '4444',
                        bonus_issued: '4444',
                        total_units: '1.35',
                        gross_refund: '1.35',
                        total_tax: '1.35',
                        net_refund: '1.35',
                    },
                ],
            },
        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL + 'wacc-check/getCompanies';
        let response = await this.axios.get(url);
        this.companies = response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },

        deleteAllData() {

            let url = this.uri + "/delete-all";

            if (confirm("Do you really want to delete all data? Make sure you have backups")) {
                this.axios.delete(url)
                    .then((response) => {
                        this.$toast.success(response.data.message, {
                            position: "bottom-right",
                        });

                        this.$root.$emit('FETCH_WACCs');

                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error("Error Deleting All data !!", {
                            position: "bottom-right",
                        });
                    });
            }
        }
    }
};
</script>
  