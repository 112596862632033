<template>
  <flex-layout>
    <CreatePopups />
    <!-- <EditPopup /> -->
    <!-- <page-header heading="Manage Popups" action="Add" @click='create'/> -->
    <page-header heading="PopUp" action="Add" 
                toggle="offcanvas"
                target="#offcanvasCreate" @click="setCreateForm" :guard="this.$route.meta.guard"
    />
    
    <Table :tableHeading="tableHeading"/>
  </flex-layout>
</template>

<script>
  // import AbsoluteLayout from "../../components/Layout/AbsoluteLayout.vue";
  import FlexLayout from "../../components/Layout/FlexLayout.vue";
  import CreatePopups from "./create.vue";
  import PageHeader from "../../components/Utility/PageHeader.vue";
  import Table from "./table.vue";
  export default {
    name: "ManagePopups",
    components: {
    // AbsoluteLayout,
    PageHeader,
    FlexLayout,
    CreatePopups,
    Table
},
    data() {
      return {
        tableHeading: [
          {
            heading: "Name",
          },
          {
            heading: "Image",
          },
          {
            heading: "Link",
          },
          {
            heading: "Status",
          },
        ],
      };
    },
    created: function()
    {
        // this.fetchPopups(); 
    },
    methods: {
      setCreateForm(){
            this.$root.$emit('CREATE');
        }
    },
  };
</script>
