<template>
    <flex-layout>
        <ComplaintModal/>
        <page-header heading="Complaints"  />
        <ComplaintTable  :tableHeading="tableHeading"/>
    </flex-layout>
</template>
  
<script>
import ComplaintModal from "@/components/Complaint/modal.vue";
import ComplaintTable from "@/components/Complaint/table.vue";
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
export default {
    name: "Complaints",
    components: {
        PageHeader,
        FlexLayout,
        ComplaintTable,
        ComplaintModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Name",
                },
                {
                    heading: "Subject",
                },
                {
                    heading: "Message",
                },
                {
                    heading: "Status",
                },
                {
                    heading:'Submitted on'
                }
            ],
        };
    },
   
};
</script>
  