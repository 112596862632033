<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Product ShowCase</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
                    <div class="col-md-12 mb-16">
                        <label for="">Title</label>
                        <input type="text" class="form-control" v-model="item.title" />
                        <ErrorSpan :error="errors.title" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Subtitle</label>
                        <input type="text" class="form-control" v-model="item.subtitle" />
                        <ErrorSpan :error="errors.subtitle" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">Product</label>
                        <select name="product_id" class="form-select" :class="{ 'border-red': errors.product_id }"
                            v-model="item.product_id">
                            <option value="" disabled selected>Select Parent</option>
                            <option v-for="(item, key) in products" :key="key" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                        <span v-if="errors.product_id" class="text-danger mt-2 font-size-13">{{
                            errors.product_id
                        }}</span>
                    </div>



                    <div class="row mb-16">
                        <label for="">Image</label>
                        <input type="file" class="form-control" ref="file" @input="handleFileUpload" />
                        <div class="col-md-6 mt-16" v-if="upload.status == false">
                            <UploadedImg :file='(storage + item.image)' :clearUploadedFile="clearUploadedFile"
                                v-if="(mode === 'Edit' && item.image)" />
                        </div>
                        <div class="col-md-6 mt-16" v-else>
                            <img :src="upload.imgPreview" alt="" height="100" width="100">
                        </div>
                        <ErrorSpan :error="errors.image" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Description</label>
                        <ckeditor v-model="item.description"></ckeditor>
                        <ErrorSpan :error="errors.description" />

                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">Display Order</label>
                        <input type="number" min="1"
                            @input="item.display_order = item.display_order < 1 ? '' : item.display_order"
                            class="form-control" :class="{ 'border-red': errors.display_order }"
                            v-model="item.display_order" />
                        <ErrorSpan :error="errors.display_order" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1"  true-value="1" false-value="0"
                                v-model="item.status" />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
                        </div>
                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">Showcase Type</label>
                        <select name="type" class="form-select" :class="{'border-red':errors.type}"
                            v-model="item.showcase_type">
                            {{ options}}
                            <option value="" disabled selected>Select type</option>
                           
                            <option v-for="(item, index) in options" :key="index" :value="item.slug">{{ item.type }}</option>
                        </select>
                        <span v-if="errors.showcase_type" class="text-danger mt-2 font-size-13">{{errors.showcase_type}}</span>
                    </div>

                    <div class="col-md-6 mb-16">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1"  true-value="1" false-value="0"
                                v-model="item.has_form" />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Has Form</label>
                        </div>
                        <ErrorSpan :error="errors.has_form" />
                    </div>

                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <button v-if="!isLoading" class="btn btn-green" type="submit">
                            <i class="ic-checkmark mr-8"></i>Save
                        </button>
                        <button v-else class="btn btn-green" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";
import CKEditor from 'ckeditor4-vue';
import UploadPreviewMixin from "@/mixins/uploadpreview.vue";
import UploadedImg from "@/components/Utility/UploadedImg.vue";


export default {
    name: "CorporateProfileModal",
    mixins: [CreateMixin, UploadPreviewMixin],
    components: { ErrorSpan, ckeditor: CKEditor.component, UploadedImg },
    props: ['products'],
    data() {
        return {
            url: 'product-showcase',
            mode: "",
            fetch_module: 'FETCH_Product_ShowCase',
            options: [
            {slug: "portfolio", type: "Portfolio Management System"},
            {slug: "advisory", type: "Advisory Service"},
        ]
        };
    },
    computed: {

    },
    mounted() {
        this.$root.$on('CREATE', () => {
            this.isEdit = false;
            this.item = {
            };
            this.errors = {};
            this.mode = "Add";
            this.clearSelectedFiles();
        });

        this.$root.$on('EDIT', (data) => {
            this.errors = {};
            this.isEdit = true;
            this.item = { ...data };
            this.mode = "Edit";
            this.clearSelectedFiles();
        });

    },
    methods: {
        handleFileUpload() {
            let image = this.$refs.file.files[0];
            this.upload.status = true;
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.upload.imgPreview = reader.result;
            }, false);

            if (image) {
                reader.readAsDataURL(image);
                this.item.image = image;
            }
        },
        clearSelectedFiles() {
            if (this.$refs.file) {
                this.$refs.file.value = null
                this.upload.status = false
                this.upload.imgPreview = null;
            }
        },
        clearUploadedFile() {
            this.item.image = null;
        }
    },
};
</script>


