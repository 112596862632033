<template>
  <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
    aria-labelledby="offcanvasCreateLabel">
    <div class="offcanvas-header">
      <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Download Category</h5>
      <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close" ref="btnClose">
        <i class="ic-close"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">

          <div class="col-md-12 mb-16">
            <label for="">Category Name</label>
            <input type="text" class="form-control" :class="{ 'border-red': errors.category_name }"    @input="createSlug"
              v-model="item.category_name" />
            <span v-if="errors.category_name" class="text-danger mt-2 font-size-13">{{ errors.category_name }}</span>
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Slug</label>
            <input type="text" class="form-control" v-model="item.slug" />
            <ErrorSpan :error="errors.slug" />
          </div>

          
          <div class="col-md-12 mb-16">
            <label for="">Display Order</label>
            <input type="number" @input="item.display_order = item.display_order < 0 ? '' : item.display_order"
              class="form-control" :class="{ 'border-red': errors.display_order }" v-model="item.display_order" />
            <ErrorSpan :error="errors.display_order" />
          </div>

          <div class="col-md-6 mb-16">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="item.status" />
              <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
            </div>
          </div>



          <div class="col-md-12">
            <button type="button" aria-label="Close" @click="closeForm" class="btn btn-red mr-16">
              <i class="ic-close mr-8"></i>Cancel
            </button>
            <button v-if="!isLoading" class="btn btn-green" type="submit">
              <i class="ic-checkmark mr-8"></i>Save
            </button>
            <button v-else class="btn btn-green" type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CreateMixin from "../../mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";

export default {
  name: "Create",
  components: {
    ErrorSpan

  },
  mixins: [CreateMixin],

  data() {
    return {
      url: 'download-category',
      mode: "",
      fetch_module: 'FETCH_DOWNLOAD_CATs'
    };
  },
  props: [],
  created() {
    this.$root.$on('CREATE', () => {
      this.isEdit = false;
      this.item = {};
      this.errors = {};
      this.mode = "Add";
    });

    this.$root.$on('EDIT', (data) => {
      this.errors = {};
      this.isEdit = true;
      this.item = { ...data };
      this.mode = "Edit";
    });
  },
  methods: {
    closeForm() {
      this.$refs.btnClose.click();
    },
    createSlug() {
      this.item.slug = this.item.category_name
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-")
        .replace(/^-+/, "")
        .replace(/-+$/, "");
    },
  },
};
</script>