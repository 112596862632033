<template>
    <flex-layout>
        <DepartmentModal :departments="departments"/>
        <DepartmentEditModal :departments="departments"/>
        <page-header heading="Department" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm" />

        <DepartmentTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import DepartmentTable from "@/components/Department/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import DepartmentModal from "@/components/Department/modal.vue";
import DepartmentEditModal from "@/components/Department/datamodal.vue";

export default {
    name: "Menus",
    components: {
        // AbsoluteLayout,
        PageHeader,
        FlexLayout,
        DepartmentTable,
        DepartmentModal,
        DepartmentEditModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "Description",
                },
                {
                    heading: "Image",
                },
                {
                    heading: "Status",
                },
                {
                    heading: "Order",
                },
            ],
            departments:[]
        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL+'department/departments';
        let response = await this.axios.get(url);
        this.departments =response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    },

};
</script>
  