<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} WACC Calculation Check</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">

                    <div class="col-md-12 mb-16">
                        <label for="">Company Name</label>
                        <select name="company_id" class="form-select" :class="{'border-red':errors.company_id}"
                            v-model="item.company_id">
                            <option value="" disabled selected>Select Company</option>
                            <option v-for="(cat,key) in companies" :key="key" :value="cat.id">{{cat.name}}</option>
                        </select>
                        <ErrorSpan :error="errors.company_id" />
                    </div>
                    <div class="row mb-16">
                        <div class="col-md-6 ">
                            <label for="">Upload file</label>
                            <input type="file" class="form-control" ref="file" @input="handleFileUpload"
                                accept=".xls,.xlsx,.csv" />
                            <ErrorSpan :error="errors.file" />
                        </div>
                    </div>

                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <button v-if="!isLoading" class="btn btn-green" type="submit">
                            <i class="ic-checkmark mr-8"></i>Save
                        </button>
                        <button v-else class="btn btn-green" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";

export default {
    name: "WaccCheckModal",
    mixins: [CreateMixin],
    components: { ErrorSpan },
    props: ['companies'],
    data() {
        return {
            url: 'wacc-check',
            mode: "",
            fetch_module:'FETCH_WACCs',
        };
    },
    computed: {

    },
    mounted() {
        this.$root.$on('CREATE', () => {
            this.isEdit = false;
            this.item = {
            };
            this.errors = {};
            this.mode = "Add";
            this.$refs.file.value = ''
            this.clearSelectedFile();
        });

        this.$root.$on('EDIT', (data) => {
            this.isEdit = true;
            this.item = { ...data };
            this.mode = "Edit";
            this.clearSelectedFile();
        });

    },
    methods: {
        handleFileUpload(e) {
            const [file] = e.target.files;
            this.item.file = file;
        },
        clearSelectedFile() {
            if (this.$refs.file) {
                this.$refs.file.value = null
            }
        }
    },
};
</script>
  
<style scoped>

</style>
  