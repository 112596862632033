<template>
    <flex-layout>
        <RightShareAllotmentModal :companies="companies" />
        <RightShareAllotmentDataModal :companies="companies" />
        
        <page-header heading="Right Share Allotment Check" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm">

            <template v-slot:delete>
                <div>
                    <button class="btn btn-sm btn-outline-danger" @click="deleteAllData()"><i
                            class="ic-checklist"></i>Delete All</button>
                </div>
            </template>

            <template v-slot:example>
            <vue-excel-xlsx class="btn btn-sm btn-outline-yellow" :data="right_share_allotment.data"
                :columns="right_share_allotment.columns" file-name="rightshareallotmenttemplate" :file-type="'xlsx'"
                sheet-name="RightShareAllotmentTemplate">
                <i class="ic-download"/> Template
            </vue-excel-xlsx>
            </template>
            <template v-slot:export>
            <a :href="uri+'/export'">
                <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
            </a>
            </template>
        </page-header>

        <RightShareAllotmentTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import RightShareAllotmentTable from "@/components/RightShareAllotment/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import RightShareAllotmentModal from "@/components/RightShareAllotment/modal.vue";
import RightShareAllotmentDataModal from "@/components/RightShareAllotment/datamodal.vue";

export default {
    name: "RightShareAllotment",
    components: {
        PageHeader,
        FlexLayout,
        RightShareAllotmentTable,
        RightShareAllotmentModal,
        RightShareAllotmentDataModal
    },
    data() {
        return {
            companies: {},
            uri : process.env.VUE_APP_API_URL + 'right-share-allotment',
            tableHeading: [
                {
                    heading: "Company",
                },
                {
                    heading: "BOID",
                },
                {
                    heading: "Share Holder No.",
                },
                {
                    heading: "Allotted Kitta",
                },
            ],
            right_share_allotment: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "share_holder_no",
                        field: "share_holder_no",
                    },
                    {
                        label: "allotted_kitta",
                        field: "allotted_kitta",
                    },

                ],
                data: [
                    {
                        boid: '1111111111111111',
                        share_holder_no: '111111',
                        allotted_kitta: '10',

                    },

                ],


            },

        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL + 'right-share-allotment/getCompanies';
        let response = await this.axios.get(url);
        this.companies = response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },

        deleteAllData() {

            let url = this.uri + "/delete-all";

            if (confirm("Do you really want to delete all data?")) {
                this.axios.delete(url)
                    .then((response) => {
                        this.$toast.success(response.data.message, {
                            position: "bottom-right",
                        });

                        this.$root.$emit('FETCH_RIGHT_ALLOTMENTs');

                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error("Error Deleting All data !!", {
                            position: "bottom-right",
                        });
                    });
            }
        }
    }
};
</script>
  <!--  -->