<template>
    <flex-layout>
        <AmlComplianceModal />
        <page-header heading="Aml Compliance" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm">
        </page-header>
        <AmlComplianceTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import AmlComplianceTable from "@/components/AmlCompliance/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import AmlComplianceModal from "@/components/AmlCompliance/modal.vue";
export default {
    name: "AmlCompliance",
    components: {
        PageHeader,
        FlexLayout,
        AmlComplianceTable,
        AmlComplianceModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "File",
                },
                {
                    heading: "Status",
                },
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  