<template>
    <flex-layout>
        <CareerOpeningModal/>
        <page-header heading="Career Openings" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />

        <CareerOpeningTable  :tableHeading="tableHeading"/>
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import CareerOpeningTable from "@/components/CareerOpenings/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import CareerOpeningModal from "@/components/CareerOpenings/modal.vue";

export default {
    name: "Menus",
    components: {
        // AbsoluteLayout,
        PageHeader,
        FlexLayout,
        CareerOpeningTable,
        CareerOpeningModal
    },
    data() {
        return {
            link_types: {},
            menu_types: {},
            parents: {},

            tableHeading: [
                {
                    heading: "Position",
                },
                {
                    heading: "Level",
                },
                {
                    heading: "No. of Openings",
                },
                {
                    heading: "Valid Till",
                },
                {
                    heading: "Status",
                }
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  