<template>
    <flex-layout>
        <TaxLiabilityCheckModal :companies="companies" />


        <TaxLiabilityCheckDataModal :companies="companies" />

        <pageheader heading="Tax Liability Check" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm">
            <template v-slot:delete>
                <div>
                    <button class="btn btn-sm btn-outline-danger" @click="deleteAllData()"><i
                            class="ic-checklist"></i>Delete All</button>
                </div>
            </template>
            <template v-slot:example>
                <vue-excel-xlsx class="btn btn-sm btn-outline-yellow" :data="tax_template.data"
                    :columns="tax_template.columns" file-name="taxliabilitychecktemplate" :file-type="'xlsx'"
                    sheet-name="TaxLiabilityCheckTemplate">
                    <i class="ic-download" /> Template
                </vue-excel-xlsx>
            </template>
            <template v-slot:export>
                <a :href="uri + '/export'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
                </a>
            </template>


        </pageheader>

        <TaxLiabilityCheckTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import TaxLiabilityCheckTable from "@/components/TaxLiabilityCheck/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import TaxLiabilityCheckModal from "@/components/TaxLiabilityCheck/modal.vue";
import TaxLiabilityCheckDataModal from "@/components/TaxLiabilityCheck/datamodal.vue";

export default {
    name: "TaxLiabilityCheck",
    components: {
        pageheader: PageHeader,
        FlexLayout,
        TaxLiabilityCheckTable,
        TaxLiabilityCheckModal,
        TaxLiabilityCheckDataModal,
    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'tax-liability-check',
            companies: {},
            tableHeading: [
                {
                    heading: "Company",
                },
                {
                    heading: "BOID",
                },
                {
                    heading: "Name",
                },
                {
                    heading: "Physical Holder No",
                },
                {
                    heading: "Bonus Issued",
                },
                {
                    heading: "Total Tax",
                },
                {
                    heading: "Remarks",
                }
            ],

            tax_template: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "physical_holder_no",
                        field: "physical_holder_no",
                    },
                    {
                        label: "name",
                        field: "name",
                    },
                    {
                        label: "bonus_issued",
                        field: "bonus_issued",
                    },
                    {
                        label: "total_tax",
                        field: "total_tax",
                    },
                    {
                        label: "remarks",
                        field: "remarks",
                    }
                ],
                data: [
                    {
                        boid: '1111111111111111',
                        physical_holder_no: '111111',
                        name: 'Firstname Surname LastName',
                        bonus_issued: '4444',
                        total_tax: '1.35',
                        remarks: 'remarks'
                    },

                ],


            },


        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL + 'tax-liability-check/getCompanies';
        let response = await this.axios.get(url);
        this.companies = response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },

        deleteAllData() {

            let url = this.uri + "/delete-all";

            if (confirm("Do you really want to delete all data? Make sure you have backups")) {
                this.axios.delete(url)
                    .then((response) => {
                        this.$toast.success(response.data.message, {
                            position: "bottom-right",
                        });

                        this.$root.$emit('FETCH_TAXs');

                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error("Error Deleting All data !!", {
                            position: "bottom-right",
                        });
                    });
            }
        }
    }
};
</script>
  